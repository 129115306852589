import { endpoints } from "constant";
import { api } from "../api/http";
import { generateUUID } from "./../../helpers/generateUUID";

export interface RegisterRequest {
  name: string;
  email: string;
  password: string;
  age: number;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  refreshToken: string;
  uniqueIdentifier: string;
}

export const authService = {
  login: async (data: LoginRequest) => {
    const uniqueIdentifier = generateUUID();
    try {
      const response = await api.post<LoginResponse>(endpoints.login, {
        username: data.email,
        password: data.password,
        uniqueIdentifier,
      });

      if (response.status === 200) {
        const result: LoginResponse = { ...response.data, uniqueIdentifier };
        return result;
      }
    } catch (err) {
      throw err;
    }
  },
};
