import { Components, Theme } from "@mui/material";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    successGradient: true;
  }
}
export const MuiButton: Components<Theme>["MuiButton"] = {
  variants: [
    {
      props: { variant: "gradient" },
      style: {
        color: "#fff",
        border: 0,
        backgroundImage: "linear-gradient(310deg,#7928ca,#ff0080)",
        letterSpacing: " -.025rem",
        boxShadow:
          "0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%)",
        backgroundSize: " 150%",
        backgroundPositionX: "25%",
        textTransform: "uppercase",
        marginBottom: 0,
        marginTop: "1.5rem",
        width: "100%",
        fontWeight: 700,
        lineHeight: "1.4",
        padding: "0.75rem 1.5rem",
        fontSize: ".75rem",
        borderRadius: "0.5rem",
        transition: "all .15s ease-in",
        cursor: "pointer",
        ":disabled": {
          backgroundColor: "transparent",
          opacity: 0.75,
          boxShadow: "none",
          color: "#fff",
          backgroundImage: "linear-gradient(310deg,#28262b,#848484)",
        },
      },
    },
    {
      props: { variant: "gradient", size: "small" },
      style: {
        marginTop: 0,
        minWidth: 34,
        padding: "0.5rem 0.75rem",
      },
    },
    {
      props: { variant: "successGradient" },
      style: {
        color: "#fff",
        border: 0,
        backgroundImage: "linear-gradient(310deg,#108329,#8fc54a)",
        letterSpacing: " -.025rem",
        boxShadow:
          "0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%)",
        backgroundSize: " 150%",
        backgroundPositionX: "25%",
        textTransform: "initial",
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 500,
        lineHeight: "1.4rem",
        padding: "0.5rem 1rem",
        fontSize: "1rem",
        borderRadius: "0.5rem",
        transition: "all .2s linear",
        cursor: "pointer",
        ":hover": {
          opacity: "0.9",
        },
      },
    },
    {
      props: { variant: "outlined", color: "error" },
      style: {
        color: "#ea0606",
        borderColor: "#ea0606",
        borderTopColor: "rgb(234, 6, 6)",
        borderRightColor: "rgb(234, 6, 6)",
        borderBottomColor: "rgb(234, 6, 6)",
        borderLeftColor: "rgb(234, 6, 6)",
        padding: "0.5rem 1rem",
        lineHeight: "1.4rem",
        borderRadius: "0.5rem",
        cursor: "pointer",
        fontSize: ".75rem",
        ":hover": {
          backgroundColor: "transparent",
          opacity: 0.75,
          boxShadow: "none",
          color: "#ea0606",
        },
      },
    },
  ],
};
