import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

export const GridItemStyled = styled(Grid)`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
`;

export const PurpleContainer = styled("div")`
  width: 54px;
  height: 54px;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
`;


export const AllTitle = styled(Typography) `
     color: #595959;
    font-weight: 400;
    font-size: 16px;
`