export const messages = {
  unknownError: "Something went wrong",
  successDownload: "Successfully downloaded",
  audience: {
    deleteConfirmationTitle: "Delete Audience",
    deleteConfirmationMessage:
      "Are you sure you want to permanently delete the selected Audience ?",
    deleted: "Audience Deleted",
    updated: "Audience Updated",
    created: "Audience Created",
    edit: "Edit Audience",
    create: "Create Audience",
  },
  apiConfiguration: {
    deleteConfirmationTitle: "Delete Api Configuration Item",
    deleteConfirmationMessage:
      "Are you sure you want to permanently delete the Api Configuration ?",
    deleted: "Api Configuration Deleted",
    updated: "Api Configuration Updated",
    created: "Api Configuration Created",
    edit: "Edit Api Configuration",
    create: "Create Api Configuration",
  },
  transaction: {
    successDownload: "Successfully downloaded",
    refund: "Refund Transaction",
    refunded: "Transaction successfully refunded",
  },
  marketingBudget: {
    deleteConfirmationTitle: "Delete Marketing Budget Item",
    deleteConfirmationMessage:
      "Are you sure you want to permanently delete the Marketing Budget ?",
    deleted: "Marketing Budget Deleted",
    updated: "Marketing Budget Updated",
    created: "Marketing Budget Created",
    edit: "Edit Marketing Budget",
    create: "Create Marketing Budget",
  },
  ad: {
    updated: "Ad Updated",
    edit: "Edit Ad",
    numberValidation: "Fields can contain only numbers",
  },
} as const;
