import { StringMappingType } from "typescript";
import { Audience } from "./Audience";
import { GoalEnum } from "./Main";

export enum CfStatusEnum {
  ReadyForPayment = "ReadyForPayment",
  WaitingForPaymentApproval = "WaitingForPaymentApproval",
  ReadyForPublish = "ReadyForPublish",
  Published = "Published",
  Canceled = "Canceled",
  Ended = "Ended",
  None = "None",
  PaymentFailed = "PaymentFailed",
  PaymentApprovalFailed = "PaymentApprovalFailed",
  PublishFailed = "PublishFailed",
  DisapprovedByFb = "DisapprovedByFb",
  Active = "Active",
}

export enum CtaButton {
  None = "None",
  SHOP_NOW = "SHOP_NOW",
  LEARN_MORE = "LEARN_MORE",
  REQUEST_TIME = "REQUEST_TIME",
}

export interface Ads {
  id: number;
  adId: string;
  adSetName: string;
  agentFullName: string;
  agentEmail: string;
  listingID: string;
  goal: GoalEnum;
  startDate: string;
  endDate: string;
  cfStatus: CfStatusEnum;
  adName: string;
  primaryText: string;
  headline: string;
  budget: number;
  spentAmount: number;
  listingState: string;
  listingCity: string;
  ctaButton: CtaButton;
  ctr: number;
  impression: number;
  results: number;
  cvr: number;
  interests: string;
  audiences: Audience[];
  locationTargeting: string;
  resultsPerOneTousentImpressions: number;
  cpc: number;
  costPerResult: number;
  linkClicks: number;
  clicks: number;
  agencyName: string;
  agencyId: number;
  utmParameters: string;
  creativeId: string;
  leadId: string;
  isReal: boolean;
}

export enum AdAgency {
  Testing = "Testing",
  Purlin = "Purlin",
  DouglasElliman = "DouglasElliman",
}

export interface CreateAdsFields {
  adId: number;
  cfStatus?: CfStatusEnum;
  utmParameter: number;
  creativeId: number;
  leadId: number;
}

export interface AdsFilters extends Record<string, unknown> {
  cfStatuses?: CfStatusEnum[];
  goal?: GoalEnum;
  agency?: AdAgency;
  listingState?: string;
  listingCity?: string;
  adId?: string | undefined;
  adSetName?: string;
  agentFullName?: string;
  agentEmail?: string;
  listingID?: string;
  utmParameters?: string;
  // need backend change cfStatuses to cfStatus
  cfStatus?: CfStatusEnum[];
  agencyName?: AdAgency;
}
