import { RootState } from "store/store";
import { ApiConfigurations } from "types";

export const selectApiConfigurations = (state: RootState) =>
  state.apiConfigurations;
export const selectApiConfigurationsData = (state: RootState) =>
  state.apiConfigurations.data;
export const selectApiConfigurationsLoading = (state: RootState) =>
  state.apiConfigurations.loading;

export const selectApiSourceTypesData = (state: RootState) =>
  state.apiConfigurations.sourceTypes;
export const selectApiSourceTypesLoading = (state: RootState) =>
  state.apiConfigurations.sourceTypesLoading;

export const selectSelectedApiConfigurationsById =
  (id?: number) =>
  (state: RootState): ApiConfigurations | undefined =>
    id
      ? state.apiConfigurations.data.find((item) => item.id === id)
      : undefined;
