import React, { FC } from "react";
import { FormikErrors, useFormik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { StyledForm } from "./styles";
import { Box } from "@mui/system";
import { CreateAdsFields } from "types";
import { LoadingButton } from "@mui/lab";
import { validationSchema } from "./validationSchema";
import { cfStatuses } from "constant";

interface Props {
  onSubmit: (values: CreateAdsFields) => void;
  initialErrors?: FormikErrors<CreateAdsFields>;
  initialValues: CreateAdsFields;
  loading: boolean;
}

export const Form: FC<Props> = ({
  initialErrors,
  onSubmit,
  initialValues,
  loading,
}) => {
  const formik = useFormik<CreateAdsFields>({
    validationSchema,
    initialValues,
    initialErrors,
    onSubmit,
  });
  return (
    <StyledForm onSubmit={formik.handleSubmit} noValidate>
      <TextField
        margin="dense"
        required
        fullWidth
        name="adId"
        label="Ad Id"
        value={formik.values.adId}
        onChange={formik.handleChange}
        error={formik.touched.adId && Boolean(formik.errors.adId)}
        helperText={formik.touched.adId && formik.errors.adId}
        autoComplete="off"
      />

      <Autocomplete
        disablePortal={false}
        onChange={(_, value) => {
          formik.setFieldValue("cfStatus", value ? value.value : undefined);
        }}
        options={cfStatuses}
        value={formik.values.cfStatus as any}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cf Status"
            name="cfStatus"
            margin="dense"
            onChange={formik.handleChange}
            error={formik.touched.cfStatus && Boolean(formik.errors.cfStatus)}
            helperText={formik.touched.cfStatus && formik.errors.cfStatus}
          />
        )}
      />

      <TextField
        margin="dense"
        fullWidth
        name="creativeId"
        label="Creative Id"
        autoComplete="off"
        required
        value={formik.values.creativeId}
        onChange={formik.handleChange}
        error={formik.touched.creativeId && Boolean(formik.errors.creativeId)}
        helperText={formik.touched.creativeId && formik.errors.creativeId}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        name="leadId"
        label="Lead Id"
        value={formik.values.leadId}
        onChange={formik.handleChange}
        error={formik.touched.leadId && Boolean(formik.errors.leadId)}
        helperText={formik.touched.leadId && formik.errors.leadId}
        autoComplete="off"
      />
      <TextField
        margin="dense"
        required
        fullWidth
        name="utmParameter"
        label="Utm Parameter"
        value={formik.values.utmParameter}
        onChange={formik.handleChange}
        autoComplete="off"
        error={
          formik.touched.utmParameter && Boolean(formik.errors.utmParameter)
        }
        helperText={formik.touched.utmParameter && formik.errors.utmParameter}
      />

      <Box mt="auto" />
      <LoadingButton
        loading={loading}
        color="primary"
        variant="gradient"
        fullWidth
        type="submit"
      >
        Submit
      </LoadingButton>
    </StyledForm>
  );
};
