import React from "react";
import { BrowserRouter, Outlet } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { MainRoute } from "routes";
import { Provider } from "react-redux";
import { store } from "store/store";
import { Authentication } from "HOC";
import { theme } from "theme";
import { Layout } from "components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Authentication>
            <Layout>
              <MainRoute />
              <ToastContainer hideProgressBar />
              <Outlet />
            </Layout>
          </Authentication>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
