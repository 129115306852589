import { MarketingBudget } from "./MarketingBudget";

export enum LocationTypeEnum {
  State = "State",
  City = "City",
  DMA = "DMA",
}

export enum AgencyEnum {
  Testing = "Testing",
  Purlin = "Purlin",
  DouglasElliman = "DouglasElliman",
}

export enum ObjectiveEnum {
  LEAD_GENERATION = "LEAD_GENERATION",
  LINK_CLICKS = "LINK_CLICKS",
}

export interface AdsConfiguration {
  users: Users[];
  id: number;
  locationType: LocationTypeEnum;
  locationName: string;
  centerPointRule: number;
  defaultLat: number;
  defaultLng: number;
  radius: number;
  isAmpAvailable: boolean;
  isCreditCardAvailable: boolean;
  objective: ObjectiveEnum;
  marketingBudgets: MarketingBudget[];
}

export interface Users {
  email: string;
  name: string;
  agency: AgencyEnum;
}

export interface AdsConfigurationFilters extends Record<string, unknown> {
  locationType?: LocationTypeEnum;
  agency?: AgencyEnum;
  objective?: ObjectiveEnum;
  userEmail?: string;
  locationName?: string;
  users?: string
}

export interface LocationTypeFilter {
  cluster?: string;
  type?: LocationTypeEnum;
}

export type LocationTypeFilterParams = {
  field: "locationType";
  value?: LocationTypeEnum;
};
