import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import { getAdsRequest, GetTableResponse } from "services";
import { TableGetParams, Ads, AdsFilters } from "types";

interface MarketingBudgetSlices {
  data: Ads[];
  loading: boolean;
  totalCount: number;
}

export const getAds = createAsyncThunk<
  GetTableResponse<Ads>,
  TableGetParams<AdsFilters>
>("ads/getAds", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getAdsRequest({
      ...params,
      sort,
      cfStatuses: params.cfStatus,
      agency: params.agencyName,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

const initialState: MarketingBudgetSlices = {
  data: [],
  loading: true,
  totalCount: 0,
};

const adsSlices = createSlice({
  name: "ads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAds.pending, (state, action) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(getAds.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getAds.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: adsReducer } = adsSlices;
