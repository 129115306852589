import { defaultPageNumber, fiftyRawsPerPage, endpoints } from "constant";
import { Ads, AdsFilters, CreateAdsFields } from "types";
import { api } from "./http";
import { GetTableResponse, TableGetRequest } from "./types";

export const getAdsRequest = async (
  params: TableGetRequest<AdsFilters>
): Promise<{
  data: GetTableResponse<Ads>;
}> => await api.post(endpoints.getAds, params);

export const updateAdRequest = async (
  id: number,
  values: CreateAdsFields
): Promise<Ads> => await api.put(`${endpoints.putAd}/${id}`, values);

export const exportAdExcel = async ({
  perPage = fiftyRawsPerPage,
  pageNumber = defaultPageNumber,
  sort,
  ...filter
}: TableGetRequest<AdsFilters>): Promise<any> =>
  await api.post(
    endpoints.adExcelExport,
    {
      perPage,
      pageNumber,
      sort,
      ...filter,
    },
    {
      responseType: "blob",
    }
  );
