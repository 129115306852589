import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment-timezone";
import LanguageIcon from "@mui/icons-material/Language";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSmall } from "store/select";
import { toggleSmall } from "store/slices";
import { AppDispatch } from "store/store";
import { selectTimezone } from "store/select/timezoneSelect";
import { changeTimezone } from "store/slices/timezoneSlices";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog() {
  const [open, setOpen] = React.useState(false);
  const isSmall = useSelector(selectIsSmall);
  const timezone = useSelector(selectTimezone);
  const [value, setValue] = React.useState(timezone);
  const dispatch = useDispatch<AppDispatch>();
  function handleMenu(e: any) {
    dispatch(toggleSmall(!isSmall));
    e.preventDefault();
    e.stopPropagation();
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(changeTimezone(value));
    handleClose();
    event.stopPropagation();
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        sx={{ color: "#3a416faa" }}
      >
        <LanguageIcon />
        {isSmall ? "" : "Change Timezone"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Please select timezone
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Autocomplete<string>
              disablePortal={false}
              value={value}
              onChange={(e, selectedItem) =>
                setValue(selectedItem ? selectedItem : "")
              }
              isOptionEqualToValue={() => true}
              options={moment.tz.names()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  name="timeZone"
                  margin="dense"
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button color="success" onClick={handleChange}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
