import { combineReducers } from "@reduxjs/toolkit";
import {
  authReducer,
  paymentReducer,
  transactionReducer,
  audienceReducer,
  apiConfigurationsReducer,
  marketingBudgetsReducer,
  applicationLogReducer,
  adsConfigurationReducer,
  adsReducer,
} from "./slices";
import { configsReducer } from "./slices/configsSlices";
import { mainAnalyticsReducer } from "./slices/MainAnalyticsSlices";
import { timezoneReducer } from "./slices/timezoneSlices";

const rootReducer = combineReducers({
  auth: authReducer,
  payment: paymentReducer,
  transaction: transactionReducer,
  audience: audienceReducer,
  configs: configsReducer,
  adsConfiguration: adsConfigurationReducer,
  apiConfigurations: apiConfigurationsReducer,
  mainAnalytics: mainAnalyticsReducer,
  marketingBudgets: marketingBudgetsReducer,
  applicationLog: applicationLogReducer,
  ads: adsReducer,
  timezone: timezoneReducer,
});

export default rootReducer;
