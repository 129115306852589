export * from "./evn";
export * from "./endpoints";
export * from "./routes";
export * from "./table";
export * from "./messages";
export * from "./staticData";
export * from "./main";

//TODO need save loco local
export const logo =
  "https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/logo-ct-dark.png";
