import * as Yup from "yup";

export const validationSchema = Yup.object({
  sourceType: Yup.string().required("Source Type is Required"),
});

export const getIsValidValues = (
  valuesArray: Array<{
    keyValue: string;
    value: string;
  }>
) => {
  const isHaveFullyData = valuesArray.find(
    (item) => item.keyValue && item.value
  );
  return !!isHaveFullyData;
};
