import React, { FC } from "react";
import { FormikErrors, useFormik } from "formik";
import { InputAdornment, TextField } from "@mui/material";
import { StyledForm } from "./styles";
import { Box } from "@mui/system";
import { CreateMarketingBudgetFelids } from "types";
import { LoadingButton } from "@mui/lab";
import { validationSchema } from "./validationSchema";

interface Props {
  onSubmit: (values: CreateMarketingBudgetFelids) => void;
  initialErrors?: FormikErrors<CreateMarketingBudgetFelids>;
  initialValues: CreateMarketingBudgetFelids;
  loading: boolean;
}

export const Form: FC<Props> = ({
  initialErrors,
  onSubmit,
  initialValues,
  loading,
}) => {
  const formik = useFormik<CreateMarketingBudgetFelids>({
    validationSchema,
    initialValues,
    initialErrors,
    onSubmit,
  });
  return (
    <StyledForm onSubmit={formik.handleSubmit} noValidate>
      <TextField
        margin="dense"
        required
        fullWidth
        name="title"
        label="Title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        autoComplete="off"
      />
      <TextField
        margin="dense"
        required
        fullWidth
        type="number"
        name="reachedCount"
        label="ReachedCount"
        value={formik.values.reachedCount}
        onChange={formik.handleChange}
        error={formik.touched.price && Boolean(formik.errors.reachedCount)}
        helperText={formik.touched.price && formik.errors.reachedCount}
        autoComplete="off"
        InputProps={{
          inputProps: { min: 1, max: 2147483647 },
        }}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        type="number"
        name="price"
        label="Price"
        value={formik.values.price}
        onChange={formik.handleChange}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
        autoComplete="off"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputProps: { min: 1, max: 2147483647 },
        }}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        type="number"
        name="period"
        label="Period"
        value={formik.values.period}
        onChange={formik.handleChange}
        error={formik.touched.period && Boolean(formik.errors.period)}
        helperText={formik.touched.period && formik.errors.period}
        autoComplete="off"
        InputProps={{
          inputProps: { min: 1, max: 2147483647 },
        }}
      />

      <Box mt="auto" />
      <LoadingButton
        loading={loading}
        color="primary"
        variant="gradient"
        fullWidth
        type="submit"
      >
        Submit
      </LoadingButton>
    </StyledForm>
  );
};
