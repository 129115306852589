import React, { FC, useEffect, useState } from "react";
import { FormDrawer } from "components";
import { Form } from "./Form";
import { useSelector } from "react-redux";
import { selectTransactionById } from "store/select/transactionsSelect";
import { toast } from "react-toastify";
import { onRefundRequest } from "services";
import { messages } from "constant";
import { PlatformEnum } from "types";

const { unknownError, transaction } = messages;

interface Props {
  onClose: () => void;
  open: boolean;
  transactionId?: number;
  onSuccess: () => void;
}

export const RefundForm: FC<Props> = ({
  onClose,
  onSuccess,
  open,
  transactionId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedTransaction = useSelector(selectTransactionById(transactionId));

  const onSubmit = async (values: { amount: number }) => {
    setLoading(true);
    try {
      await onRefundRequest({
        adInternalId: selectedTransaction?.adId || 0,
        amount: values.amount,
        platform: PlatformEnum.facebook,
      });
      const successMessage = transaction.refunded;
      toast.success(successMessage);
      setLoading(false);
      onSuccess();
    } catch (err: any) {
      setLoading(false);
      toast.error(err?.response?.data?.Message || unknownError);
    }
  };

  useEffect(() => {
    if (transactionId && !selectedTransaction) {
      onClose();
      toast.error("Something went wrong. Can not find transaction");
    }
  }, [transactionId, selectedTransaction, onClose]);

  if (!selectedTransaction) return null;

  return (
    <FormDrawer open={open} title={transaction.refund} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        loading={loading}
        initialValues={{
          amount: 0,
        }}
        maxValue={selectedTransaction.amount}
      />
    </FormDrawer>
  );
};
