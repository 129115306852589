import React, { FC, memo } from "react";
import { Typography, Box } from "@mui/material";
import {
  FileDownload as FileDownloadIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { noop } from "lodash";
import { ButtonStyled, FlexDiv } from "./styles";
import { LoadingButton } from "@mui/lab";
interface Props {
  title: string;
  btnText?: string;
  addDisable?: boolean;
  isAddButton?: boolean;
  onClick?: () => void;
  onExport?: () => void;
  hasExport?: boolean;
  exportLoading?: boolean;
}

export const PageTitleComponent: FC<Props> = ({
  title,
  btnText = "Add New",
  addDisable = false,
  isAddButton = false,
  hasExport = false,
  onClick = noop,
  onExport = noop,
  exportLoading = false,
}) => {
  return (
    <FlexDiv>
      <Typography variant="h6" color="primary">
        {title}
      </Typography>
      <Box display="flex">
        {hasExport && (
          <LoadingButton
            loading={exportLoading}
            onClick={onExport}
            startIcon={<FileDownloadIcon />}
            variant="outlined"
          >
            Export
          </LoadingButton>
        )}
        {isAddButton && (
          <ButtonStyled
            variant="successGradient"
            onClick={onClick}
            disabled={addDisable}
            startIcon={<AddIcon fontSize="small" />}
          >
            {btnText}
          </ButtonStyled>
        )}
      </Box>
    </FlexDiv>
  );
};

export const PageTitle = memo(PageTitleComponent);
