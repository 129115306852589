import React, { FC, useMemo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectTimezone } from "store/select/timezoneSelect";
import { dateFormatterNew } from "helpers";
import { Tooltip } from "@mui/material";

interface Props {
  datetime: string;
}

export const getTimeByTimezone = (datetime: string, timezone: string) => {
  try {
    const utcTime = moment.utc(datetime);
    const response = utcTime.tz(timezone).format("YYYY-MM-DD HH:mm:ss.SSS");
    return response;
  } catch (error) {
    return "";
  }
};

export const ChangeDatetimeByTimezone: FC<Props> = ({ datetime }) => {
  const selectedTimezone = useSelector(selectTimezone);
  const parsedDateAsUtc = useMemo(
    () => (datetime ? getTimeByTimezone(datetime, selectedTimezone) : " "),
    [selectedTimezone, datetime]
  );
  return (
    <Tooltip title={parsedDateAsUtc} placement="bottom-start">
      <span>{parsedDateAsUtc}</span>
    </Tooltip>
  );
};
