import React, { FC } from "react";
import { StringLiteral } from "typescript";
import styles from "./styles.module.scss";

interface Props {
  btnText: string;
  onClick?: () => void;
}

export const Button: FC<Props> = ({ btnText, onClick }) => {
  return (
    <>
      <button className={styles.sign_btn} onClick={onClick}>
        {btnText}
      </button>
    </>
  );
};
