import { FC, memo } from "react";
import { List } from "@mui/material";
import { SubMenuType } from "types";
import { useLocation } from "react-router-dom";
import { SubMenuItems } from "./SubMenuItems";
import { useSelector } from "react-redux";
import { selectIsSmall } from "store/select/index";

interface Props {
  subMenu: SubMenuType[];
}
export const SubMenuComponent: FC<Props> = ({ subMenu }) => {
  const isSmall = useSelector(selectIsSmall);
  const { pathname } = useLocation();
  return (
    <>
      <List component="div" disablePadding>
        {subMenu.map((listElm) => (
          <SubMenuItems
            isActive={pathname === listElm.path}
            isSmall={isSmall}
            key={listElm.path}
            {...listElm}
          />
        ))}
      </List>
    </>
  );
};

export const SubMenu = memo(SubMenuComponent);
