import { styled } from "@mui/material/styles";

export const DrawerContent = styled("div")`
  padding: 20px 24px;
  max-height: calc(100vh - 156px);
  overflow-y: auto;
`;
export const ButtonContainer = styled("div")`
  padding: 0 24px 20px;
`;
export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 24px; */
`;
