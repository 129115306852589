import { GoalEnum, CfStatusEnum, AdAgency } from "types";

export const goalTypeFilterData: {
  text: GoalEnum;
  value: GoalEnum;
}[] = [
  {
    text: GoalEnum.LEAD_GENERATION,
    value: GoalEnum.LEAD_GENERATION,
  },
  {
    text: GoalEnum.LINK_CLICKS,
    value: GoalEnum.LINK_CLICKS,
  },
];

export const cfStatusFilterData = Object.values(CfStatusEnum).map((item) => ({
  text: item,
  value: item,
}));

export const adAgenciesFilterData = Object.values(AdAgency).map((item) => ({
  text: item,
  value: item,
}));
