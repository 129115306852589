import React, { FC, memo, useMemo } from "react";
import { Chip } from "@mui/material";
import { LastRefundStatus } from "types";
import { TableCellTooltip } from "components";

interface Props {
  refundState: LastRefundStatus;
}

const getRefundStatus = (
  refundState: LastRefundStatus
) => {
  switch (refundState) {
    case LastRefundStatus.CompletedNotSuccessfully:
      return "Non-refunded"
      ;
    case LastRefundStatus.CompletedSuccessfully:
      return  "Refunded"
      ;
    case LastRefundStatus.Created:
      return "Refund requested"
      ;
    case LastRefundStatus.None:
      return "Running"
      ;
    default:
      return "None"
      ;
  }
};

const RefundStateCellComponent: FC<Props> = ({ refundState }) => {
  const chipProps = useMemo(
    () => getRefundStatus(refundState),
    [refundState]
  );
  return  <TableCellTooltip   value={chipProps} />;
};

export const RefundStateCell = memo(RefundStateCellComponent);
