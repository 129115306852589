import React, { FC } from "react";
import { DeleteOutline } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

interface ValuesArray {
  keyValue: string;
  value: string;
}

const initialMultiInputValue: ValuesArray = {
  keyValue: "",
  value: "",
};

interface Props {
  valuesArray: ValuesArray[];
  setValuesArray: React.Dispatch<React.SetStateAction<ValuesArray[]>>;
  hasError?: boolean;
}

export const MultiInputField: FC<Props> = ({
  setValuesArray,
  valuesArray,
  hasError = false,
}) => {
  const handleChange =
    (index: number, targetName: "keyValue" | "value") => (e: any) => {
      setValuesArray((prev) =>
        prev.map((item, i) => {
          if (index === i) {
            return {
              ...item,
              [targetName]: e.target.value,
            };
          }
          return item;
        })
      );
    };

  const addOneMore = () => {
    setValuesArray((prev) => [...prev, initialMultiInputValue]);
  };

  const deleteElement = (index: number) => () => {
    setValuesArray((prev) => prev.filter((item, i) => index !== i));
  };

  return (
    <Box mt={3}>
      <Typography variant="subtitle1">Value *</Typography>
      <Box py={0.5} />
      {valuesArray.map(({ keyValue, value }, index) => (
        <Box display="flex" key={index} gap={2} mb={2} alignItems="center">
          <TextField
            value={keyValue}
            onChange={handleChange(index, "keyValue")}
            label="Json Key"
          />
          <TextField
            value={value}
            onChange={handleChange(index, "value")}
            label="Json Value"
          />
          <IconButton
            onClick={deleteElement(index)}
            style={{ height: "fit-content" }}
          >
            <DeleteOutline />
          </IconButton>
        </Box>
      ))}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          {hasError ? (
            <Typography color={"red"}>Values are Required</Typography>
          ) : null}
        </Grid>
        <Grid xs={"auto"} item>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            onClick={addOneMore}
          >
            Add one more
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
