import { RootState } from "store/store";
import { AdsConfiguration } from "types";

export const selectAdsConfigurations = (state: RootState) =>
  state.adsConfiguration;
export const selectAdsConfigurationsData = (state: RootState) =>
  state.adsConfiguration.data;
export const selectAdsConfigurationsLoading = (state: RootState) =>
  state.adsConfiguration.loading;

export const selectAdConfigurationsById =
  (id?: number) =>
  (state: RootState): AdsConfiguration | undefined =>
    id ? state.adsConfiguration.data.find((item) => item.id === id) : undefined;
