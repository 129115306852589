import { RootState } from "store/store";
import { Transaction } from "types";

export const selectTransactions = (state: RootState) => state.transaction;
export const selectTransactionsData = (state: RootState) =>
  state.transaction.data;
export const selectTransactionsLoading = (state: RootState) =>
  state.transaction.loading;

export const selectTransactionById =
  (id?: number) =>
  (state: RootState): Transaction | undefined =>
    id ? state.transaction.data.find((item) => item.id === id) : undefined;
