import * as yup from "yup";

export const validationSchema = yup.object({
  price: yup
    .number()
    .min(1, "Price must contain positive value")
    .max(9999, "Please enter no more than 4 characters")
    .required("Price is Required"),
  title: yup.string().trim().required("Title is Required"),
  period: yup
    .number()
    .min(1, "Period must contain positive value")
    .max(99, "Period must contain no more than 2 characters")
    .required("Period is Required"),
  reachedCount: yup
    .number()
    .min(1, "Reached count must contain positive value")
    .max(99999, "Please enter no more than 5 characters")
    .required("ReachedCount is Required"),
});
