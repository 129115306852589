import { Navigate, RouteObject } from "react-router-dom";
import {
  Dashboard,
  AdsConfigurations,
  FBAds,
  Transactions,
  MarketingBudgets,
  ApiConfiguration,
  ApplicationLogs,
  Analytics,
} from "views";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PaymentsIcon from "@mui/icons-material/Payments";
import ApiIcon from "@mui/icons-material/Api";
import InsightsIcon from "@mui/icons-material/Insights";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { MenuItemsType } from "types";
import { Error404 } from "views/Error404";

export const routerConfig: RouteObject[] = [
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/api-configuration",
    element: <ApiConfiguration />,
  },
  // {
  //   path: "/users",
  //   element: <Users />,
  // },
  {
    path: "/ads-configuration",
    element: <AdsConfigurations />,
  },
  {
    path: "/fb-ads",
    element: <FBAds />,
  },
  // {
  //   path: "/audience",
  //   element: <Audience />,
  // },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/marketing-budget",
    element: <MarketingBudgets />,
  },

  {
    path: "/application-logs",
    element: <ApplicationLogs />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },

  {
    path: "/signin",
    element: <Navigate to="/" />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

//TODO need create full route map, need make request to backend
export const routerMenu: MenuItemsType[] = [
  {
    name: "Dashboard",
    path: "/",
    icon: <PeopleAltIcon fontSize="small" />,
    hidden: true,
  },
  // {
  //   name: "Users",
  //   icon: <PeopleAltIcon fontSize="small" />,
  //   path: "/users",
  // },
  {
    name: "Ads",
    icon: <InsightsIcon fontSize="small" />,
    path: "/ads",
    subMenu: [
      {
        name: "Ads Configuration",
        path: "/ads-configuration",
      },
      {
        name: "FB Ads",
        path: "/fb-ads",
      },
    ],
  },

  // {
  //   name: "Audience",
  //   icon: <GroupIcon fontSize="small" />,
  //   path: "/audience",
  // },
  {
    name: "Payments",
    icon: <PaymentsIcon fontSize="small" />,
    path: "/payments",
    subMenu: [
      {
        name: "Transactions",
        path: "/transactions",
      },
      // {
      //   name: "Refunds",
      //   path: "/refunds",
      // },
      {
        name: "Marketing Budget",
        path: "/marketing-budget",
      },
    ],
  },
  {
    name: "API Configuration",
    icon: <ApiIcon fontSize="small" />,
    path: "/api-configuration",
  },
  {
    name: "Logs",
    icon: <RateReviewIcon fontSize="small" />,
    path: "/logs",
    subMenu: [
      {
        name: "Application Logs",
        path: "/application-logs",
      },
      // {
      //   name: "User Logs",
      //   path: "/user-logs",
      // },
    ],
  },
  // {
  //   name: "Analytics",
  //   icon: <BarChartIcon fontSize="small" />,
  //   path: "/analytics",
  // },
];
