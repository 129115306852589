import React, { FC, memo, ReactElement } from "react";
import { Drawer, Box, Typography, Divider, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { DrawerBody } from "./styles";
import { noop } from "lodash";

interface Props {
  open: boolean;
  children?: ReactElement;
  title: string;
  onClose?: () => void;
}

const FormDrawerComponent: FC<Props> = ({
  open,
  children,
  title,
  onClose = noop,
}) => {
  return (
    <Drawer anchor={"right"} open={open}>
      <DrawerBody>
        <Box
          pl={3}
          pr={2}
          py={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Divider />
        {children}
      </DrawerBody>
    </Drawer>
  );
};
export const FormDrawer = memo(FormDrawerComponent);
