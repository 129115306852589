export enum LastRefundStatus {
  None = "None",
  Created = "Created",
  CompletedSuccessfully = "CompletedSuccessfully",
  CompletedNotSuccessfully = "CompletedNotSuccessfully",
}

export interface Transaction {
  id: number;
  creditCard: string;
  invoiceNumber: string;
  amount: number;
  adId: number;
  createDate: Date;
  type: number;
  orderOrTransactionId: number;
  listingId: string;
  marketingBudgetId: number;
  lastRefundState: LastRefundStatus;
  isAvailableForRefund: boolean;
}

export interface TransactionFilters extends Record<string, unknown> {
  creditCard?: string;
  invoiceNumber?: string;
  amount?: number;
  adId?: number;
  type?: number;
  orderOrTransactionId?: number;
  listingId?: string;
}

export enum PlatformEnum {
  facebook = "Facebook_Instagram",
  google = "Google",
}

export interface PatchRefundParams {
  adInternalId: number;
  amount: number;
  platform: PlatformEnum;
}
