import React, { FC, ReactNode, memo, useMemo } from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import {
  DrawerStyled,
  MainDiv,
  MainList,
  PageDiv,
  Logo,
  LogoDiv,
  IconDiv,
} from "./styles";
import { MenuItems } from "components/MenuItems";
import { routerMenu } from "routes";
import { logo } from "constant";
import { Link, useLocation } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleSmall } from "store/slices/index";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSmall } from "store/select/index";
import { AppDispatch } from "store/store";
import DraggableDialog from "components/Popup/TimezonePopup";

interface Props {
  children: ReactNode;
}

export const Layout: FC<Props> = memo(({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isSmall = useSelector(selectIsSmall);
  const { pathname } = useLocation();

  function handleMenu(e: any) {
    dispatch(toggleSmall(!isSmall));

    e.preventDefault();
    e.stopPropagation();
  }

  const activeParrent = useMemo(() => {
    let activeParrent = "";
    let activeSubMenuItem = "";
    for (let index = 0; index < routerMenu.length; index++) {
      if (activeParrent) {
        break;
      }
      const element = routerMenu[index];
      if (element && element.subMenu) {
        for (let i = 0; i < element.subMenu.length; i++) {
          const subElement = element.subMenu[i];
          if (subElement.path === pathname) {
            activeParrent = element.path;
          }
        }
      } else {
        if (element.path === pathname) {
          activeParrent = element.path;
        }
      }
    }

    return activeParrent;
  }, [pathname]);

  return (
    <MainDiv>
      <DrawerStyled
        variant="permanent"
        anchor="left"
        open={false}
        isSmall={isSmall}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <LogoDiv>
            <Logo src={logo} />
            <Typography align="center" variant="h6" color="primary">
              Client Finder
            </Typography>
          </LogoDiv>
        </Link>
        <Divider />
        <div>
          <MainList>
            {routerMenu.map((item) => (
              <MenuItems
                key={item.path}
                isActive={activeParrent === item.path}
                {...item}
              />
            ))}
          </MainList>
        </div>
        <IconDiv isSmall={isSmall}>
          <DraggableDialog />
          <IconButton onClick={handleMenu}>
            {isSmall ? (
              <MenuIcon fontSize="medium" />
            ) : (
              <MenuOpenIcon fontSize="medium" />
            )}
          </IconButton>
        </IconDiv>
      </DrawerStyled>
      <PageDiv isSmall={isSmall}>{children}</PageDiv>
    </MainDiv>
  );
});
