import React, { FC, memo } from "react";
import { ListItemText, Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  ListItemButtonStyled,
  ListItemIconStyled,
  ListItemStyled,
  MyStyledDiv,
  StyledLink,
} from "components/MenuItems/styles";
import { SubMenu } from "./Submenu";
import { MenuItemsType } from "types";
import { useSelector } from "react-redux";
import { selectIsSmall } from "store/select/index";

interface Props extends MenuItemsType {
  isActive: boolean;
}

const MenuItemsComponent: FC<Props> = ({
  isActive,
  icon,
  name,
  path,
  hidden = false,
  subMenu,
}) => {
  const [open, setOpen] = React.useState(true);
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (subMenu) {
      setOpen(!open);
      e.preventDefault();
    }
    e.stopPropagation();
  };
  if (hidden) return <></>;
  return (
    <>
      <ListItemStyled disablePadding isActive={isActive}>
        <StyledLink to={path}>
          <ListItemButtonStyled onClick={handleClick}>
            <MyStyledDiv isActive={isActive}>
              <ListItemIconStyled isActive={isActive}>
                {icon}
              </ListItemIconStyled>
            </MyStyledDiv>
            <ListItemText primary={name} />

            {!!subMenu && <> {open ? <ExpandLess /> : <ExpandMore />}</>}
          </ListItemButtonStyled>
        </StyledLink>
      </ListItemStyled>

      {!!subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit={false}>
          <SubMenu subMenu={subMenu} />
        </Collapse>
      )}
    </>
  );
};

export const MenuItems = memo(MenuItemsComponent, (prevProps, nextProps) => {
  return prevProps.isActive === nextProps.isActive;
});
