import React from "react";
import { MRT_ColumnDef } from "material-react-table";
import { LastRefundStatus, Transaction } from "types";
import { TableCellTooltip, TableCardNumber, TableActionMenu } from "components";
import { RefundStateCell } from "./RefundStateCell";
import { ChangeDatetimeByTimezone } from "components/DateTimeComponent";
interface Props {
  // filter: TransactionFilters;
  // onFilterChange: (filter: TransactionFilters) => void;
  onRefund: (id: number) => void;
}

export const getColumns = ({
  onRefund,
}: Props): MRT_ColumnDef<Transaction>[] => [
  {
    accessorKey: "id",
    header: "Id",
    enableSorting: true,
    minSize: 60,
  },
  {
    accessorKey: "creditCard",
    header: "Credit Card",
    enableSorting: false,
    minSize: 120,
    Cell: ({ cell }) => <TableCardNumber value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "invoiceNumber",
    header: "Invoice Number",
    enableSorting: true,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "amount",
    header: "Amount",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "adId",
    header: "Ad Id",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "createDate",
    header: "Create Date",
    enableSorting: true,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => (
      <ChangeDatetimeByTimezone datetime={cell.getValue<string>()} />
    ),
  },
  {
    accessorKey: "orderOrTransactionId",
    header: "Order/Transaction Id",
    enableSorting: true,
    minSize: 180,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "marketingBudgetId",
    header: "Marketing Budget Id",
    enableSorting: true,
    enableColumnFilter: false,
    minSize: 180,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "lastRefundState",
    header: "Refund State",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => {
      return (
        <RefundStateCell refundState={cell.getValue<LastRefundStatus>()} />
      );
    },
  },
  {
    accessorKey: "listingId",
    header: "Listing Id",
    enableSorting: false,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "type",
    header: "Type",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 120,
    Cell({ cell }) {
      const type = 1;
      return <TableCellTooltip value={type === 1 ? "Card" : "AMP"} />;
    },
  },
  {
    accessorKey: "isAvailableForRefund",
    header: "",
    enableSorting: false,
    size: 70,
    Cell(params) {
      const isAvailableForRefund = params.cell.getValue<boolean>();
      if (!isAvailableForRefund) return null;
      return (
        <TableActionMenu
          id={Number(params.row.id)}
          isDeletable={false}
          editText="Refund"
          onEdit={onRefund}
        />
      );
    },
  },
];
