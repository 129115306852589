import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Input } from "components";
import { useDispatch, useSelector } from "react-redux";
import { login, clearAuthError } from "store/slices";
import { AppDispatch } from "store/store";
import { selectAuthError, selectAuthLoading } from "store/select";
import { Alert, CircularProgress, Collapse } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  SignInDiv,
  LeftContainer,
  RightContainer,
  Card,
  CardHeader,
  Lines,
  RocketImgDiv,
  RocketImg,
  LinesImg,
} from "./styles";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(selectAuthLoading);
  const errorMessage = useSelector(selectAuthError);
  const handleEmailChange = (em: any) => {
    setEmail(em.target.value);
    if (errorMessage) {
      dispatch(clearAuthError());
    }
  };

  const handlePasswordChange = (em: any) => {
    setPassword(em.target.value);
    if (errorMessage) {
      dispatch(clearAuthError());
    }
  };

  const onClick = (e: any) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <div>
    <SignInDiv onSubmit={onClick}>
      <LeftContainer>
        <Card>
          <CardHeader>
            <h4 className={styles.card_h4}>Sign In</h4>
            <p className={styles.card_p}>
              Enter your email and password to sign in
            </p>
          </CardHeader>
          <div className={styles.card_body}>
            <Input
              value={email}
              onChange={handleEmailChange}
              type="email"
              name="email"
              placeholder="Email"
              required
            />
            <Input
              value={password}
              name="password"
              onChange={handlePasswordChange}
              type="password"
              placeholder="Password"
            />

            <Collapse in={!!errorMessage}>
              <Alert severity="error">{errorMessage}</Alert>
            </Collapse>
            <div className={styles.form_check}></div>
            <LoadingButton
              loading={loading}
              type="submit"
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={16}
                />
              }
              variant="gradient"
              onClick={onClick}
            >
              SIGN IN
            </LoadingButton>
          </div>
        </Card>
      </LeftContainer>
      <RightContainer>
        <Lines>
          <LinesImg src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/shapes/pattern-lines.svg" />
        <RocketImgDiv>
          <RocketImg src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/illustrations/chat.png" />
          <h4 className={styles.right_h4}>"Attention is the new currency"</h4>
          <p className={styles.right_p}>
            The more effortless the writing looks, the more effort the writer
            actually put into the process.
          </p>
        </RocketImgDiv>
        </Lines>
      </RightContainer>
    </SignInDiv>
    </div>
  );
};
