import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions } from "store/slices";
import { AppDispatch } from "store/store";
import { selectTransactions } from "store/select/transactionsSelect";
import { DataTable, PageTitle } from "components";
import { SortModel, TransactionFilters, Transaction } from "types";
import { defaultPageNumber, defaultRowsPerPage, messages } from "constant";
import { getColumns } from "./columns";
import { RefundForm } from "./RefundForm";
import { exportExcel } from "services/api";
import { getFormattedSort } from "helpers";
import { toast } from "react-toastify";

const { unknownError, successDownload } = messages;

export const Transactions = () => {
  const [filter, setFilter] = useState<TransactionFilters>({});
  const [sort, setSortModel] = useState<SortModel>([]);
  const [perPage, setPerPage] = useState(defaultRowsPerPage);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [selectedId, setId] = useState<number>();
  const [isOpenFrom, setOpenForm] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { data, loading, totalCount } = useSelector(selectTransactions);
  const dispatch = useDispatch<AppDispatch>();

  const onPageSizeChange = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
    setPageNumber(defaultPageNumber);
  }, []);

  const onPageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  const handleSortChange = useCallback((newSort: SortModel) => {
    setSortModel(newSort);
    setPageNumber(defaultPageNumber);
  }, []);

  useEffect(() => {
    dispatch(getTransactions({ pageNumber, perPage, sort, ...filter }));
  }, [pageNumber, perPage, sort, filter, dispatch]);

  const onRefund = useCallback((id: number) => {
    setId(id);
    setOpenForm(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    if (selectedId) {
      setId(undefined);
    }
  }, [selectedId]);

  const onSuccessCreateUpdate = () => {
    handleCloseForm();
    dispatch(getTransactions({ pageNumber, perPage, sort, ...filter }));
  };

  const columns = useMemo(() => {
    return getColumns({ onRefund });
  }, [onRefund]);

  const onExport = async () => {
    try {
      setDownloadLoading(true);
      const sortFormatted = getFormattedSort(sort);
      const response = await exportExcel({
        pageNumber,
        perPage,
        sort: sortFormatted,
        ...filter,
      });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "transactions.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setDownloadLoading(false);
      toast.success(successDownload);
    } catch (e) {
      toast.error(unknownError);
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <PageTitle
        exportLoading={downloadLoading}
        title="Transactions"
        hasExport
        onExport={onExport}
      />
      <DataTable<Transaction, TransactionFilters>
        data={data}
        sort={sort}
        columns={columns}
        loading={loading}
        perPage={perPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortChange={handleSortChange}
        enableColumnResizing
        columnResizeMode="onChange"
        filters={filter}
        onFilterChange={setFilter}
      />
      <RefundForm
        onClose={handleCloseForm}
        open={isOpenFrom}
        transactionId={selectedId}
        onSuccess={onSuccessCreateUpdate}
      />
    </>
  );
};
