import { Drawer, List } from "@mui/material";
import { styled, css } from "@mui/material/styles";

export const MainList = styled(List)``;

export const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop: string) => prop !== "isSmall",
})<{
  isSmall: boolean;
}>(
  ({ theme, isSmall }) => css`
    & > div {
      background-color: #f9f9f9;
      color: #344734;
      border-right: 0.5px solid #0000001f;
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
      width: ${isSmall ? "96px" : "240px"};
      transition: all ease-in-out 0.3s;
      overflow: hidden;
    }
  `
);

export const PageDiv = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isSmall",
})<{
  isSmall: boolean;
}>(
  ({ theme, isSmall }) => css`
    padding-top: 20px;
    padding-left: ${isSmall ? "126px" : "270px"};
    padding-right: 30px;
    padding-bottom: 16px;
    min-height: 100vh;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    transition: all ease-out 0.3s;
  `
);

export const MainDiv = styled("div")`
  min-height: 100vh;
  background-color: #f9f9f9;
  height: 100%;
`;

export const Logo = styled("img")`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  margin-right: 22px;
`;

export const LogoDiv = styled("div")`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 32px;
  overflow-x: hidden;
  padding-bottom: 20px;
  overflow: hidden;
`;

export const IconDiv = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isSmall",
})<{
  isSmall: boolean;
}>(
  ({ theme, isSmall }) => css`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: ${isSmall ? "center" : "flex-start"};
`)
