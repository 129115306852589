import React, { FC, useState, useMemo } from "react";
import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MultiInputField } from "./MultiInputField";
import {
  DrawerContent,
  ButtonContainer,
  Container,
  StyledForm,
} from "./styles";
import { ApiSourceType, ApiConfigurationsCreateFields } from "types";
import { validationSchema, getIsValidValues } from "./validationSchema";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const initialMultiInputValue: ValuesArray = {
  keyValue: "",
  value: "",
};
interface CreateApiConfigurationField {
  sourceType?: ApiSourceType;
}

interface ValuesArray {
  keyValue: string;
  value: string;
}

interface Props {
  loading: boolean;
  sourceTypes: Array<
    | { label: ApiSourceType; value: number }
    | { label: string; value: undefined }
  >;
  onSubmit: (values: ApiConfigurationsCreateFields) => void;
  initialValues: {
    value: string;
    sourceType?: ApiSourceType;
  };
  onClose: () => void;
}

export const Form: FC<Props> = ({
  loading,
  onSubmit,
  initialValues,
  sourceTypes,
  onClose,
}) => {
  const [valuesArray, setValuesArray] = useState<ValuesArray[]>(
    getValuesFromJson(initialValues.value)
  );
  const [touchedValues, setTouchedValues] = useState(false);

  const formik = useFormik<CreateApiConfigurationField>({
    validationSchema,
    initialValues,
    onSubmit: formSubmit,
  });

  function getValuesFromJson(jsonValue: string): ValuesArray[] {
    try {
      if (!jsonValue) return [initialMultiInputValue];
      const parsedJSon = JSON.parse(jsonValue);
      if (!parsedJSon) return [initialMultiInputValue];
      const result: ValuesArray[] = [];
      for (const key in parsedJSon) {
        if (Object.prototype.hasOwnProperty.call(parsedJSon, key)) {
          const element = parsedJSon[key];
          result.push({
            keyValue: key,
            value: element,
          });
        }
      }
      return result;
    } catch (e) {
      toast.error("Cannot parse value field as Json");
      onClose();
      return [initialMultiInputValue];
    }
  }

  function formSubmit(values: CreateApiConfigurationField) {
    setTouchedValues(true);
    const isValidValues = getIsValidValues(valuesArray);
    if (values.sourceType && isValidValues) {
      const getValueObjectByArray = valuesArray.reduce((prev, item) => {
        if (item.keyValue && item.value) {
          prev = {
            ...prev,
            [item.keyValue]: item.value,
          };
        }
        return prev;
      }, {});

      const jsonFormatted = JSON.stringify(getValueObjectByArray);
      onSubmit({
        value: jsonFormatted,
        sourceType: values.sourceType,
      });
    }
  }

  const handleSubmit = () => {
    setTouchedValues(true);
    formik.submitForm();
  };

  const isValidValues = useMemo(
    () => getIsValidValues(valuesArray),
    [valuesArray]
  );

  return (
    <Container>
      <DrawerContent>
        <StyledForm>
          <Autocomplete
            disablePortal={false}
            onChange={(_, value) => {
              formik.setFieldValue(
                "sourceType",
                value ? value.value : undefined
              );
            }}
            options={sourceTypes}
            value={formik.values.sourceType as any}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Type"
                name="sourceType"
                margin="dense"
                required
                error={
                  formik.touched.sourceType && Boolean(formik.errors.sourceType)
                }
                helperText={
                  formik.touched.sourceType && formik.errors.sourceType
                }
              />
            )}
          />
        </StyledForm>
        <MultiInputField
          valuesArray={valuesArray}
          setValuesArray={setValuesArray}
          hasError={touchedValues && !isValidValues}
        />
      </DrawerContent>
      <Box mt="auto" />
      <Divider />
      <ButtonContainer>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="gradient"
          fullWidth
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </ButtonContainer>
    </Container>
  );
};
