import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import { getAdsConfigurationRequest, GetTableResponse } from "services";
import {
  AdsConfiguration,
  AdsConfigurationFilters,
  TableGetParams,
  Transaction,
  TransactionFilters,
} from "types";

interface AdsConfigurationSlices {
  data: AdsConfiguration[];
  loading: boolean;
  totalCount: number;
}

export const getAdsConfiguration = createAsyncThunk<
  GetTableResponse<AdsConfiguration>,
  TableGetParams<AdsConfigurationFilters>
>("adsConfiguation/getAdsConfiguration", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getAdsConfigurationRequest({
      ...params,
      sort,
      userEmail: params?.users,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

const initialState: AdsConfigurationSlices = {
  data: [],
  loading: true,
  totalCount: 0,
};

const adsConfigurationSlices = createSlice({
  name: "adsConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdsConfiguration.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAdsConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getAdsConfiguration.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: adsConfigurationReducer } = adsConfigurationSlices;
