import React, { FC } from "react";
import { Button } from "components";
import { Typography } from "@mui/material";
import { LeftContainer, RocketImg } from "views/SignIn/styles";
import {
  ImgDiv,
  MainContainer,
  RightContainerNew,
  TypographyPurple,
} from "./styles";
import { useNavigate } from "react-router-dom";

export const Error404: FC = () => {
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <MainContainer>
        <LeftContainer sx={{ pl: 6, pt: 14 }}>
          <TypographyPurple variant="h1">Error 404</TypographyPurple>
          <Typography variant="h3">Page not found</Typography>
          <Typography
            component="p"
            sx={{ fontSize: 18, color: "#5c5c5c", pt: 2 }}
          >
            We suggest you to go to the homepage while solve this issue.
          </Typography>
          <Button onClick={navigateHome} btnText="Go to homepage"></Button>
        </LeftContainer>
        <RightContainerNew>
          <ImgDiv>
            <RocketImg src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/illustrations/error-404.png" />
          </ImgDiv>
        </RightContainerNew>
      </MainContainer>
    </>
  );
};
