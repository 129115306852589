import { styled, css } from "@mui/material/styles";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    transition: all ease 0.6s;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: ${isActive ? "#0000000a" : "initial"};
  `
);

export const ListItemStyledActive = styled(ListItemStyled)`
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.01),
    0 8px 25px -10px rgba(0, 0, 0, 0.2), 0 -6px 16px -6px rgba(0, 0, 0, 0);
`;

export const ListItemButtonStyled = styled(ListItemButton)`
  border-radius: 8px;
  overflow: hidden;
`;

export const ListItemButtonSubmenu = styled(ListItemButton)`
  background-color: transparent;
  border-radius: 8px;
  &:hover {
    background-color: transparent;
  }
`;
export const ListItemIconStyled = styled(ListItemIcon, {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<{ isActive?: boolean }>(
  ({ theme, isActive }) => css`
    & {
      min-width: 0;
      transition: all ease 0.6s;
      color: ${isActive ? "#fff" : theme.palette.primary.main};
    }
  `
);

export const MyStyledDiv = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(
  ({ theme, isActive }) => css`
    transition: all ease 0.6s;
    min-width: 32px;
    min-height: 32px;
    background-color: #fff;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
    margin-right: 22px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${isActive ? "#cb0c9f" : "initial"};
  `
);

export const MyStyledDivActive = styled(MyStyledDiv)`
  background-color: #cb0c9f;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

export const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop: string) =>
    prop !== "isActive" && prop !== "isSmall",
})<{
  isActive: boolean;
  isSmall: boolean;
}>(
  ({ theme, isActive, isSmall }) => css`
    transition: all ease-out 0.6s;
    color: ${isActive ? "#3a416f" : "#3a416faa"};
    font-weight: ${isActive ? "500" : "400"};
    text-align: ${isSmall ? "center" : "initial"};
    overflow: hidden;
  `
);
