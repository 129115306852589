import moment from "moment-timezone";
import { createSlice } from "@reduxjs/toolkit";

const timezoneToken = moment.tz.guess();

interface TimezoneStore {
  timezoneToken: string;
}

const initialState: TimezoneStore = {
  timezoneToken: timezoneToken,
};

const timezoneSlice = createSlice({
  name: "timezone",
  initialState,
  reducers: {
    changeTimezone(state, action) {
      state.timezoneToken = action.payload;
    },
  },
});

export const { reducer: timezoneReducer } = timezoneSlice;
export const { changeTimezone } = timezoneSlice.actions;
