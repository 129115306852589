import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { DataTable, PageTitle } from "components";
import { SortModel, ApplicationLog, ApplicationLogFilters } from "types";
import { defaultPageNumber, defaultRowsPerPage } from "constant";
import { getColumns } from "./columns";
import { selectApplicationLog } from "store/select";
import { getApplicationLog } from "store/slices";

const columns = getColumns();

export const ApplicationLogs = () => {
  const [filter, setFilter] = useState<ApplicationLogFilters>({});
  const [sort, setSortModel] = useState<SortModel>([]);
  const [perPage, setPerPage] = useState(defaultRowsPerPage);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const { data, loading, totalCount } = useSelector(selectApplicationLog);
  const dispatch = useDispatch<AppDispatch>();

  const onPageSizeChange = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
    setPageNumber(defaultPageNumber);
  }, []);

  const onPageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  const handleSortChange = (newSort: SortModel) => {
    setSortModel(newSort);
    setPageNumber(defaultPageNumber);
  };

  useEffect(() => {
    dispatch(getApplicationLog({ pageNumber, perPage, sort, ...filter }));
  }, [pageNumber, perPage, sort, filter, dispatch]);

  return (
    <>
      <PageTitle title="Application Logs" />
      <DataTable<ApplicationLog, ApplicationLogFilters>
        data={data}
        sort={sort}
        columns={columns}
        loading={loading}
        perPage={perPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortChange={handleSortChange}
        enableColumnResizing
        columnResizeMode="onChange"
        filters={filter}
        onFilterChange={setFilter}
      />
    </>
  );
};
