import { AdAgency, ApiSourceType, CfStatusEnum } from "types";

export const apiSourceTypes = Object.values(ApiSourceType).map((item) => ({
  label: item,
  value: item,
}));

export const cfStatuses = Object.values(CfStatusEnum).map((item) => ({
  label: item,
  value: item,
}));

export const adAgencies = Object.values(AdAgency).map((item) => ({
  label: item,
  value: item,
}));
