import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmationPopup, DataTable, PageTitle } from "components";
import { getApiConfigurations, getSourceTypes } from "store/slices";
import {
  ApiConfigurations,
  ApiConfigurationsFilter,
  ApiConfigurationsFilterChange,
  SortModel,
} from "types";
import { defaultPageNumber, defaultRowsPerPage, messages } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { ApiConfigurationForm } from "./ApiConfigurationForm";
import { toast } from "react-toastify";
import { deleteApiConfigurationRequest } from "services";
import { selectApiConfigurations } from "store/select";
import { getColumns } from "./columns";

const { apiConfiguration, unknownError } = messages;

export const ApiConfiguration: FC = () => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [filter, setFilter] = useState<ApiConfigurationsFilter>({});
  const [sort, setSortModel] = useState<SortModel>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [perPage, setPerPage] = useState(defaultRowsPerPage);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [selectedId, setId] = useState<number>();
  const [isOpenFrom, setOpenForm] = useState(false);
  const { data, loading, totalCount } = useSelector(selectApiConfigurations);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      getApiConfigurations({
        pageNumber,
        perPage,
        sort,
        ...filter,
      })
    );
  }, [perPage, dispatch, pageNumber, sort, filter]);

  const onPageSizeChange = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
    setPageNumber(defaultPageNumber);
  }, []);

  const onPageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  const handleSortChange = useCallback((newSort: SortModel) => {
    setSortModel(newSort);
    setPageNumber(defaultPageNumber);
  }, []);

  const onDelete = useCallback((id: number) => {
    setOpenConfirmation(true);
    setId(id);
  }, []);

  const onCloseDeletePopup = () => {
    setOpenConfirmation(false);
    if (selectedId) {
      setId(undefined);
    }
  };

  const onSubmitDelete = async () => {
    if (selectedId) {
      setDeleteLoading(true);
      try {
        await deleteApiConfigurationRequest(selectedId);
        toast.success(apiConfiguration.deleted);
        dispatch(
          getApiConfigurations({
            perPage,
            pageNumber,
            sort,
            ...filter,
          })
        );
        dispatch(getSourceTypes());
        setDeleteLoading(false);
        onCloseDeletePopup();
      } catch (err) {
        setDeleteLoading(false);
        toast.error(unknownError);
      }
    }
  };

  const handleOpenForm = useCallback(() => {
    setOpenForm(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    if (selectedId) {
      setId(undefined);
    }
  }, [selectedId]);

  const onSuccessCreateUpdate = useCallback(() => {
    dispatch(
      getApiConfigurations({
        pageNumber,
        perPage,
        sort,
        ...filter,
      })
    );
    dispatch(getSourceTypes());
    handleCloseForm();
  }, [perPage, pageNumber, dispatch, handleCloseForm, sort, filter]);

  const onEdit = useCallback((id: number) => {
    setId(id);
    handleOpenForm();
  }, []);

  const columns = useMemo(
    () => getColumns({ onDelete, onEdit }),
    [onDelete, onEdit]
  );

  return (
    <>
      <PageTitle
        title="Api Configuration"
        isAddButton
        onClick={handleOpenForm}
      />
      <DataTable<ApiConfigurations, ApiConfigurationsFilter>
        columns={columns}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        data={data}
        loading={loading}
        perPage={perPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        sort={sort}
        onSortChange={handleSortChange}
        enableColumnResizing
        columnResizeMode="onChange"
        filters={filter}
        onFilterChange={setFilter}
      />
      <ConfirmationPopup
        onClose={onCloseDeletePopup}
        open={openConfirmation}
        title={apiConfiguration.deleteConfirmationTitle}
        message={apiConfiguration.deleteConfirmationMessage}
        onSubmit={onSubmitDelete}
        loading={deleteLoading}
      />
      <ApiConfigurationForm
        onClose={handleCloseForm}
        open={isOpenFrom}
        apiConfigurationId={selectedId}
        onSuccess={onSuccessCreateUpdate}
      />
    </>
  );
};
