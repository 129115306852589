import { routes } from "constant";
import { Routes, Route, Navigate } from "react-router-dom";
import { SignIn } from "views";

export const AuthRouter = () => {
  return (
    <Routes>
      <Route path={routes.main} element={<SignIn />} />
      <Route path={routes.signIn} element={<SignIn />} />
      <Route path="*" element={<Navigate to={routes.signIn} />} />
    </Routes>
  );
};
