import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import { getTransactionsRequest, GetTableResponse } from "services";
import { TableGetParams, Transaction, TransactionFilters } from "types";

interface TransactionSlices {
  data: Transaction[];
  loading: boolean;
  totalCount: number;
}

export const getTransactions = createAsyncThunk<
  GetTableResponse<Transaction>,
  TableGetParams<TransactionFilters>
>("transaction/getTransaction", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getTransactionsRequest({ ...params, sort });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

const initialState: TransactionSlices = {
  data: [],
  loading: true,
  totalCount: 0,
};

const transactionSlices = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: transactionReducer } = transactionSlices;
