import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import { getMarketingBudgetsRequest, GetTableResponse } from "services";
import { TableGetParams, MarketingBudget, MarketingBudgetFilter } from "types";

interface MarketingBudgetSlices {
  data: MarketingBudget[];
  loading: boolean;
  totalCount: number;
}

export const getMarketingBudgets = createAsyncThunk<
  GetTableResponse<MarketingBudget>,
  TableGetParams<MarketingBudgetFilter>
>("marketing/getMarketing", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getMarketingBudgetsRequest({ ...params, sort });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

const initialState: MarketingBudgetSlices = {
  data: [],
  loading: true,
  totalCount: 0,
};

const marketingBudgetsSlices = createSlice({
  name: "marketing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarketingBudgets.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMarketingBudgets.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getMarketingBudgets.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: marketingBudgetsReducer } = marketingBudgetsSlices;
