import {
  ApiConfigurations,
  ApiConfigurationsCreateFields,
  ApiSourceTypes,
} from "types";
import { defaultPageNumber, defaultRowsPerPage, endpoints } from "constant";
import { api } from "./http";
import { GetApiConfigurationRequestParams, GetTableResponse } from "./types";

export const getApiConfigurationRequest = async ({
  perPage = defaultRowsPerPage,
  pageNumber = defaultPageNumber,
  sort,
  sourceType,
}: GetApiConfigurationRequestParams): Promise<{
  data: GetTableResponse<ApiConfigurations>;
}> =>
  await api.post(endpoints.getApiConfigurations, {
    perPage,
    pageNumber,
    sort,
    sourceType,
  });

export const deleteApiConfigurationRequest = async (id: number): Promise<any> =>
  await api.delete(`${endpoints.apiConfigurations}/${id}`, {
    params: { company: 1 },
  });

export const createApiConfigurationRequest = async (
  values: ApiConfigurationsCreateFields
): Promise<ApiConfigurations> =>
  await api.post(endpoints.apiConfigurations, { ...values, sourceType: 51 });

export const updateApiConfigurationRequest = async (
  id: number,
  values: ApiConfigurationsCreateFields
): Promise<ApiConfigurations> =>
  await api.put(`${endpoints.apiConfigurations}/${id}`, {
    ...values,
    sourceType: 51,
  });

export const getSourceTypesRequest = async (): Promise<{
  data: ApiSourceTypes[];
}> => api.get(endpoints.getSourceTypes);
