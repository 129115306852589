import React from "react";
import { Ads, GoalEnum } from "types";
import { MRT_ColumnDef } from "material-react-table";
import { TableCellTooltip, TableActionMenu } from "components";
import { twoSymbolsAfterDot } from "helpers";
import { Tooltip, Typography } from "@mui/material";
import { ChangeDatetimeByTimezone } from "components/DateTimeComponent";
import {
  adAgenciesFilterData,
  cfStatusFilterData,
  goalTypeFilterData,
} from "./selectFilterData";

interface Props {
  onEdit: (id: number) => void;
}

export const getColumns = ({ onEdit }: Props): Array<MRT_ColumnDef<Ads>> => [
  {
    accessorKey: "adId",
    header: "DB Id",
    enablePinning: true,
    enableResizing: false,
    enableSorting: true,
    minSize: 160,
    Cell: ({ cell }) => {
      return <TableCellTooltip value={cell.getValue<string>()} />;
    },
  },
  {
    accessorKey: "adSetName",
    header: "Ad Set Name",
    enablePinning: true,
    filterVariant: "text",
    enableSorting: false,
    enableResizing: false,
    minSize: 400,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "agentFullName",
    header: "Agent Full Name",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "agentEmail",
    header: "Agent Email",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "listingID",
    header: "Listing ID",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "goal",
    header: "Goal",
    minSize: 160,
    enableSorting: false,
    filterSelectOptions: goalTypeFilterData,
    filterVariant: "select",
    Cell: ({ cell }) => {
      const goal = cell.getValue<GoalEnum>();
      return (
        <TableCellTooltip
          value={
            goal === GoalEnum.LEAD_GENERATION
              ? "Lead Generation"
              : "Link Clicks"
          }
        />
      );
    },
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => {
      return <ChangeDatetimeByTimezone datetime={cell.getValue<string>()} />;
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => (
      <ChangeDatetimeByTimezone datetime={cell.getValue<string>()} />
    ),
  },
  {
    accessorKey: "cfStatus",
    header: "CF Status",
    enableSorting: false,
    filterSelectOptions: cfStatusFilterData,
    filterVariant: "multi-select",
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
    minSize: 160,
  },
  {
    accessorKey: "primaryText",
    header: "Primary Text",
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "headline",
    header: "Headline",
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "budget",
    header: "Budget",
    enableSorting: false,
    minSize: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "spentAmount",
    header: "Spent Amount",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "listingState",
    header: "Listing State",
    minSize: 160,
    enableSorting: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "utmParameters",
    header: "UTM",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "listingCity",
    header: "Listing City",
    enableColumnFilter: false,
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "ctaButton",
    header: "CTA button label",
    enableSorting: false,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "ctr",
    header: "CTR",
    enableSorting: true,
    minSize: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Tooltip title={cell.getValue<string>()}>
        <Typography>{twoSymbolsAfterDot(cell.getValue<string>())}</Typography>
      </Tooltip>
    ),
  },
  {
    accessorKey: "impression",
    header: "Impression",
    enableSorting: false,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "results",
    header: "Results",
    enableSorting: false,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "cvr",
    header: "CVR",
    enableSorting: true,
    minSize: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Tooltip title={cell.getValue<string>()}>
        <Typography>{twoSymbolsAfterDot(cell.getValue<string>())}</Typography>
      </Tooltip>
    ),
  },
  {
    accessorKey: "isReal",
    header: "Is Real",
    minSize: 160,
    Cell: ({ cell }) => (
      <TableCellTooltip value={cell.getValue<string>() ? "Yes" : "No"} />
    ),
  },
  {
    accessorKey: "interests",
    header: "Interests",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "locationTargeting",
    header: "Location Targeting",
    minSize: 160,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "resultsPerOneTousentImpressions",
    header: "Results per 1K impressions",
    enableSorting: true,
    minSize: 240,
    enableColumnFilter: false,
    Header: ({ column }) => (
      <TableCellTooltip value={column.columnDef.header} />
    ),
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "cpc",
    header: "CPC",
    enableSorting: true,
    minSize: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Tooltip title={cell.getValue<string>()}>
        <Typography>{twoSymbolsAfterDot(cell.getValue<string>())}</Typography>
      </Tooltip>
    ),
  },
  {
    accessorKey: "costPerResult",
    header: "Cost Per Result ($)",
    enableSorting: true,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <TableCellTooltip value={twoSymbolsAfterDot(cell.getValue<string>())} />
    ),
  },
  {
    accessorKey: "linkClicks",
    header: "Link Clicks",
    enableSorting: true,
    minSize: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "clicks",
    header: "Clicks",
    minSize: 120,
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "agencyName",
    header: "Agency Name",
    enableSorting: false,
    minSize: 160,
    filterVariant: "select",
    filterSelectOptions: adAgenciesFilterData,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "agencyId",
    header: "Agency Id",
    minSize: 120,
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "id",
    header: "",
    enableSorting: false,
    enableResizing: false,
    size: 60,
    enableColumnFilter: false,
    Cell: ({ cell }) => {
      return (
        <TableActionMenu
          id={cell.getValue<number>()}
          isDeletable={false}
          onEdit={onEdit}
        />
      );
    },
  },
];
