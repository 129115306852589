import React, { FC, useMemo, useState } from "react";
import { Form } from "./Form";
import { CreateMarketingBudgetFelids } from "types";
import {
  createMarketingBudgetsRequest,
  updateMarketingBudgetsRequest,
} from "services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectMarketingBudgetById } from "store/select";
import { FormDrawer } from "components";
import { messages } from "constant";

const { unknownError, marketingBudget } = messages;

interface Props {
  onClose: () => void;
  open: boolean;
  audienceId?: number;
  onSuccess: () => void;
}

const defaultInitialValues: any = {
  title: "",
  period: undefined,
  price: undefined,
  reachedCount: undefined,
};

const handleCreateUpdateAudience = (
  values: CreateMarketingBudgetFelids,
  audienceId?: number
) =>
  audienceId
    ? updateMarketingBudgetsRequest(audienceId, values)
    : createMarketingBudgetsRequest(values);

export const MarketingBudgetForm: FC<Props> = ({
  onClose,
  audienceId,
  open,
  onSuccess,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedAudience = useSelector(selectMarketingBudgetById(audienceId));

  const onSubmit = async (values: CreateMarketingBudgetFelids) => {
    setLoading(true);
    try {
      await handleCreateUpdateAudience(values, audienceId);
      const successMessage = audienceId
        ? marketingBudget.updated
        : marketingBudget.created;

      toast.success(successMessage);
      setLoading(false);
      onSuccess();
    } catch (err: any) {
      setLoading(false);
      toast.error(err?.response?.data?.Message || unknownError);
    }
  };

  const initialValues: CreateMarketingBudgetFelids = useMemo(() => {
    if (!selectedAudience) {
      return defaultInitialValues;
    }
    return {
      clusterId: "",
      ...selectedAudience,
    };
  }, [selectedAudience]);

  const title = audienceId ? marketingBudget.edit : marketingBudget.create;

  return (
    <FormDrawer open={open} title={title} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        loading={loading}
        initialValues={initialValues}
      />
    </FormDrawer>
  );
};
