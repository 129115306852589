import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTransactionMock } from "services";

interface PaymentSlices {
  data: any[];
  loading: boolean;
}

export const getTransaction = createAsyncThunk<any>(
  "payment/getPayment",
  async (_, thunkAPI) => {
    try {
      const response = await getTransactionMock();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(false);
    }
  }
);

const initialState: PaymentSlices = {
  data: [],
  loading: true,
};

const paymentSlices = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransaction.pending, (state, action) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTransaction.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: paymentReducer } = paymentSlices;
export const {} = paymentSlices.actions;
