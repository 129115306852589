import * as yup from "yup";

export const validationSchema = yup.object({
  fbId: yup.string().trim().required("Fb Id is Required"),
  name: yup.string().trim().required("Name is Required"),
  clusterId: yup.string().trim().required("Cluster Id is Required"),
  specialAdAudienceFbId: yup
    .string()
    .trim()
    .required("Special Ad Audience FbId is Required"),
  specialAdAudienceName: yup
    .string()
    .trim()
    .required("Special Ad Audience Name is Required"),
});
