import { Components, Theme } from "@mui/material";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    successGradient: true;
  }
}
export const MuiChip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    root: {
      // padding: "0.55em 0.9em",
      // fontSize: "12px",
      // fontWeight: 700,
      // color: "#fff",
      // textAlign: "center",
      // whiteSpace: "nowrap",
      // verticalAlign: "baseline",
      // borderRadius: "8px",
      // width: "100%",
      // background: "unset",
    },
  },
  // background-image: linear-gradient(310deg,#17ad37,#98ec2d);
  variants: [
    {
      props: { color: "warning" },
      style: {
        backgroundImage: "linear-gradient(310deg,#7928ca,#ff0080);",
      },
    },
    {
      props: { color: "success" },
      style: {
        backgroundImage: "linear-gradient(310deg,#141727,#3a416f)",
      },
    },
    {
      props: { color: "primary" },
      style: {
        backgroundImage: "linear-gradient(310deg,#17ad37,#98ec2d)",
      },
    },
    {
      props: { color: "secondary" },
      style: {
        backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd);",
      },
    },
  ],
};
