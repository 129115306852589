import { FC, ReactElement } from "react";
import { CardContent, Grid, Typography, Card, Skeleton } from "@mui/material";
import { AllTitle, PurpleContainer } from "./styles";

interface Props {
  title: string;
  value?: number | string;
  icon: ReactElement;
  loading: boolean;
}

export const StatCard: FC<Props> = ({ title, value, icon, loading }) => {
  return (
    <Grid item>
      <Card
        sx={{
          boxShadow: "0 20px 27px 0 rgb(0 0 0 / 5%)",
          bgcolor: "white",
          borderRadius: 2.5,
          height: "86px",
          width: "260px",
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={9.5}>
              <AllTitle>{title}</AllTitle>
              {loading ? (
                <Skeleton variant="text" width="45%" height="32px" />
              ) : (
                <Typography variant="h5" color="primary" fontWeight="800">
                  {value}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2.5}>
              <PurpleContainer>{icon}</PurpleContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
