import React from "react";
import { MRT_ColumnDef } from "material-react-table";

import { ApplicationLog } from "types";
import { TableCellTooltip } from "components";
import { ChangeDatetimeByTimezone } from "components/DateTimeComponent";

export const getColumns = (): MRT_ColumnDef<ApplicationLog>[] => [
  {
    accessorKey: "id",
    header: "Id",
    enableSorting: true,
    enableColumnFilter: false,
    size: 100,
    minSize: 50,
  },
  {
    accessorKey: "date",
    header: "Date",
    enableSorting: true,
    enableColumnFilter: false,
    minSize: 160,
    Cell: ({ cell }) => (
      <ChangeDatetimeByTimezone datetime={cell.getValue<string>()} />
    ),
  },
  {
    accessorKey: "userName",
    header: "User Name",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "level",
    header: "Level",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "ipAddress",
    header: "IpAddress",
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "machineName",
    header: "Machine Name",
    enableSorting: false,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "logger",
    header: "Logger",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "message",
    header: "Message",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "errorClass",
    header: "Error Class",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "errorMethod",
    header: "Error Method",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "exception",
    header: "Exception",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "sourceId",
    header: "Source Id",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "errorUniqueId",
    header: "Error Unique Id",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
];
