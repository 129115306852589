import React from "react";
import { LoadingGif } from "assets";
import { LoadingContainer } from "./styles";

export const InitialLoading = () => {
  return (
    <LoadingContainer>
      <img src={LoadingGif} alt="loading Gif" />
    </LoadingContainer>
  );
};
