import React, { FC, useId } from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Users } from "types";
import { Grid, IconButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  users: Users[];
}

export const TotalAvatars: FC<Props> = ({ users }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = useId();

  return (
    <>
      <AvatarGroup max={3} onClick={handleClick} sx={{ flexDirection: "row" }}>
        {users.map((user, index) => (
          <Avatar
            key={index}
            alt={user.name}
            sx={{ bgcolor: "#cb0c9f" }}
            src="/broken-image.jpg"
          ></Avatar>
        ))}
      </AvatarGroup>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id={id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
        <Box sx={{ p: 2, width: 500 }}>
          {users.map((user, index) => (
            <Grid
              container
              spacing={6}
              alignItems="center"
              paddingBottom={2}
              key={index}
            >
              <Grid item xs={8}>
                <Typography color="#3a416f" fontWeight={500}>
                  {user.name}
                </Typography>
                <Typography color="#3a416faa" fontWeight={400}>
                  {user.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="#cb0c9f" fontWeight={600}>
                  {user.agency}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Popover>
    </>
  );
};
