import { styled } from "@mui/material/styles";
import { Card, Grid, Typography } from "@mui/material";

export const TypographyPurple2 = styled(Typography)`
  background: -webkit-linear-gradient(310deg, #7928ca, #ff0080);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const CardStyled = styled(Card)`
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  background-color: #fff;
  border-radius: 20px;
  width: auto;
`;

export const AgentsTitle = styled(Typography)`
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1px;
`;

export const GoalTitle = styled(Typography)`
  color: #404040;
  font-weight: 500;
  display: inline-block;
  margin-top: 4px;
`;
