import { Nullable } from "types";

export const localStorageKeys = {
  auth: "auth",
};

interface AuthData {
  token: string;
  refreshToken: string;
  uniqueIdentifier: string;
}

export const localStorageService = {
  setToken: (token: string) => {
    const authData = localStorageService.getAuth() || {};
    const data = {
      ...authData,
      token,
    };
    localStorageService.setAuth(data);
  },
  getToken: () => {
    const authData = localStorageService.getAuth();
    if (!authData) return null;
    return authData.token;
  },
  setRefreshToken: (refreshToken: string) => {
    const authData = localStorageService.getAuth() || {};
    const data = {
      ...authData,
      refreshToken,
    };
    localStorageService.setAuth(data);
  },
  getRefreshToken: () => {
    const authData = localStorageService.getAuth();
    if (!authData) return null;
    return authData.refreshToken;
  },
  setUniqueIdentifier: (uniqueIdentifier: string) => {
    const authData = localStorageService.getAuth() || {};
    const data = {
      ...authData,
      uniqueIdentifier,
    };
    localStorageService.setAuth(data);
  },
  getUniqueIdentifier: () => {
    const authData = localStorageService.getAuth();
    if (!authData) return null;
    return authData.uniqueIdentifier;
  },
  getAuth: (): Nullable<AuthData> => {
    const authData = localStorage.getItem(localStorageKeys.auth);
    if (!authData || authData === "undefined") return null;
    return JSON.parse(authData);
  },
  setAuth: (data: any) => {
    localStorage.setItem(localStorageKeys.auth, JSON.stringify(data));
  },
};
