import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConfigSlices {
  isSmall: boolean;
}

const initialState: ConfigSlices = {
  isSmall: false,
};

const configSlices = createSlice({
  name: "configs",
  initialState,
  reducers: {
    toggleSmall(state, action: PayloadAction<boolean>) {
      state.isSmall = action.payload;
    },
  },
});

export const { reducer: configsReducer } = configSlices;
export const { toggleSmall } = configSlices.actions;
