import React, { FC, memo } from "react";
import { TableCellTooltip } from "components";
import { Typography, Box } from "@mui/material";
import { CreditCard as CreditCardIcon } from "@mui/icons-material";

export const TableCardNumberComponent: FC<{ value?: number | string }> = ({
  value,
}) => {
  if (!value) return null;
  return (
    <Box display="flex" alignItems="center">
      <CreditCardIcon color="primary" />
      <Typography
        color="#37474f"
        fontWeight={600}
        letterSpacing={-1}
        variant="body2"
        ml={1}
      >
        <TableCellTooltip value={value} />
      </Typography>
    </Box>
  );
};

export const TableCardNumber = memo(TableCardNumberComponent);
