import { RootState } from "store/store";
import { MarketingBudget } from "types";

export const selectMarketingBudget = (state: RootState) =>
  state.marketingBudgets;
export const selectMarketingBudgetData = (state: RootState) =>
  state.marketingBudgets.data;
export const selectMarketingBudgetLoading = (state: RootState) =>
  state.marketingBudgets.loading;

export const selectMarketingBudgetById =
  (id?: number) =>
  (state: RootState): MarketingBudget | undefined =>
    id ? state.marketingBudgets.data.find((item) => item.id === id) : undefined;
