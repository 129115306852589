import React from "react";
import { MRT_ColumnDef } from "material-react-table";
import {
  AdsConfiguration,
  LocationTypeEnum,
  MarketingBudget as MarketingBudgetType,
  ObjectiveEnum,
  Users,
} from "types";
import { TableCellTooltip } from "components";
import { TotalAvatars } from "./UsersCell";
import { MarketingBudget } from "./MarketingBudget";

const locationTypeFilterData: {
  text: LocationTypeEnum;
  value: LocationTypeEnum;
}[] = [
  {
    text: LocationTypeEnum.City,
    value: LocationTypeEnum.City,
  },
  {
    text: LocationTypeEnum.DMA,
    value: LocationTypeEnum.DMA,
  },
  {
    text: LocationTypeEnum.State,
    value: LocationTypeEnum.State,
  },
];

const objectiveFilter: {
  text: ObjectiveEnum;
  value: ObjectiveEnum;
}[] = [
  {
    text: ObjectiveEnum.LEAD_GENERATION,
    value: ObjectiveEnum.LEAD_GENERATION,
  },
  {
    text: ObjectiveEnum.LINK_CLICKS,
    value: ObjectiveEnum.LINK_CLICKS,
  },
];

export const getColumns = (): MRT_ColumnDef<AdsConfiguration>[] => [
  {
    accessorKey: "users",
    header: "Users",
    enableSorting: false,
    minSize: 160,
    Cell: ({ cell }) => <TotalAvatars users={cell.getValue<Users[]>()} />,
  },
  {
    accessorKey: "locationType",
    header: "Location Type",
    enableSorting: false,
    minSize: 160,
    filterSelectOptions: locationTypeFilterData,
    filterVariant: "select",
    Cell({ cell }) {
      const type = cell.getValue<LocationTypeEnum>() as any;
      const label =
        type === LocationTypeEnum.City
          ? "City"
          : type === LocationTypeEnum.DMA
          ? "DMA"
          : "State";

      return (
        <>
          <TableCellTooltip value={label} />
        </>
      );
    },
  },

  {
    accessorKey: "locationName",
    header: "Location name",
    enableSorting: true,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "centerPointRule",
    header: "Center point rule",
    enableSorting: true,
    minSize: 160,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
    enableColumnFilter: false,
  },
  {
    accessorKey: "defaultLat",
    header: "Default Lat",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
    enableColumnFilter: false,
  },

  {
    accessorKey: "defaultLng",
    header: "Default Lng",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
    enableColumnFilter: false,
  },
  {
    accessorKey: "radius",
    header: "Radius",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
    enableColumnFilter: false,
  },
  {
    accessorKey: "isAmpAvailable",
    header: "Has AMP",
    enableSorting: true,
    minSize: 120,
    Cell: ({ cell }) => (
      <TableCellTooltip value={cell.getValue<string>() ? "Yes" : "No"} />
    ),
    enableColumnFilter: false,
  },
  {
    accessorKey: "isCreditCardAvailable",
    header: "Has credit card",
    enableSorting: true,
    minSize: 160,
    Cell: ({ cell }) => (
      <TableCellTooltip value={cell.getValue<string>() ? "Yes" : "No"} />
    ),
    size: 120,
    enableColumnFilter: false,
  },
  {
    accessorKey: "objective",
    header: "Objective",
    enableSorting: true,
    minSize: 160,
    filterVariant: "select",
    filterSelectOptions: objectiveFilter,
    Cell({ cell, row }) {
      const type = cell.getValue<ObjectiveEnum>();
      return (
        <TableCellTooltip
          value={
            type === ObjectiveEnum.LEAD_GENERATION
              ? "Lead Generation"
              : "Link Clicks"
          }
        />
      );
    },
  },
  {
    accessorKey: "marketingBudgets",
    header: "Marketing Budget",
    enableSorting: true,
    enableResizing: false,
    minSize: 160,
    enableColumnFilter: false,
    Cell: ({ cell }) => {
      return (
        <MarketingBudget
          marketingBudget={cell.getValue<MarketingBudgetType[]>()}
        />
      );
    },
  },
];
