import { createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { MuiButton } from "./MuiButton";
import { MuiChip } from "./MuiChip";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3a416f",
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: "#ea0606",
    },
  },
  components: {
    MuiButton,
    MuiChip,
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
  },
});
