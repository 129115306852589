import React, { FC, useMemo, useState, useEffect } from "react";
import { Form } from "./Form";
import { CreateAdsFields } from "types";
import { updateAdRequest } from "services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectAdsById } from "store/select";
import { FormDrawer } from "components";
import { messages } from "constant";

const { ad, unknownError } = messages;
interface Props {
  onClose: () => void;
  open: boolean;
  id?: number;
  onSuccess: () => void;
}

const defaultInitialValues: any = {
  adId: "",
  cfStatus: undefined,
  creativeId: "",
  leadId: "",
  utmParameter: "",
};

export const AdForm: FC<Props> = ({ onClose, id, open, onSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedAd = useSelector(selectAdsById(id));

  useEffect(() => {
    if (id && !selectedAd) {
      onClose();
    }
  }, [id, selectedAd, onClose, open]);

  const onSubmit = async (values: CreateAdsFields) => {
    if (id) {
      setLoading(true);
      try {
        await updateAdRequest(id, values);
        toast.success(ad.updated);
        setLoading(false);
        onSuccess();
      } catch (err: any) {
        setLoading(false);
        if(err.response.data.ValidationMessages){
        err.response.data.ValidationMessages.map(function() {
          return toast.error(ad.numberValidation);  
        })
      } else {
        toast.error(unknownError);
      }
      }
    }
  };

  const initialValues: CreateAdsFields = useMemo(() => {
    if (!selectedAd) {
      return defaultInitialValues;
    }
    const {
      adId,
      cfStatus,
      creativeId,
      leadId,
      utmParameters: utmParameter,
    } = selectedAd;
    return { adId, cfStatus, creativeId, leadId, utmParameter };
  }, [selectedAd]);

  return (
    <FormDrawer open={open} title={ad.edit} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        loading={loading}
        initialValues={initialValues}
      />
    </FormDrawer>
  );
};
