import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAnalyticsRequest } from "services";
import { MainAnalytics } from "types";

interface MainAnalyticsSlices {
  data: MainAnalytics | null;
  loading: boolean;
}

export const getMainAnalytics = createAsyncThunk<MainAnalytics>(
  "mainAnalytics/getMainAnalytics",
  async (_, thunkAPI) => {
    try {
      const response = await getAnalyticsRequest();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(false);
    }
  }
);

const initialState: MainAnalyticsSlices = {
  data: null,
  loading: true,
};

const mainAnalyticsSlices = createSlice({
  name: "mainAnalytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMainAnalytics.pending, (state, action) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(getMainAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getMainAnalytics.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: mainAnalyticsReducer } = mainAnalyticsSlices;
export const {} = mainAnalyticsSlices.actions;
