import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { DataTable, PageTitle } from "components";
import { SortModel, Ads, AdsFilters } from "types";
import { defaultPageNumber, fiftyRawsPerPage, messages } from "constant";
import { getColumns } from "./columns";
import { selectAds } from "store/select";
import { getAds } from "store/slices";
import { AdForm } from "./AdForm";
import { exportAdExcel } from "services";
import { getFormattedSort } from "helpers";
import { toast } from "react-toastify";

const { unknownError, successDownload } = messages;

export const FBAds = () => {
  const [filter, setFilter] = useState<AdsFilters>({});
  const [sort, setSortModel] = useState<SortModel>([
    { desc: true, id: "adId" },
  ]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [perPage, setPerPage] = useState(fiftyRawsPerPage);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const { data, loading, totalCount } = useSelector(selectAds);
  const [selectedId, setId] = useState<number>();
  const [isOpenFrom, setOpenForm] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onPageSizeChange = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
    setPageNumber(defaultPageNumber);
  }, []);

  const onPageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  const handleSortChange = useCallback((newSort: SortModel) => {
    setSortModel((prevSort) =>
      newSort.length === 0 && prevSort.length === 0 ? prevSort : newSort
    );
    setPageNumber(defaultPageNumber);
  }, []);

  useEffect(() => {
    dispatch(getAds({ pageNumber, perPage, sort, ...filter }));
  }, [pageNumber, perPage, sort, filter, dispatch]);

  const handleOpenForm = useCallback(() => {
    setOpenForm(true);
  }, []);

  const onEdit = useCallback((id: number) => {
    setId(id);
    handleOpenForm();
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    if (selectedId) {
      setId(undefined);
    }
  }, [selectedId]);

  const columns = useMemo(() => getColumns({ onEdit }), [onEdit]);

  const onSuccessCreateUpdate = useCallback(() => {
    handleCloseForm();
    dispatch(getAds({ pageNumber, perPage, sort, ...filter }));
  }, [perPage, pageNumber, dispatch, handleCloseForm, sort, filter]);

  const onExport = async () => {
    try {
      setDownloadLoading(true);
      const sortFormatted = getFormattedSort(sort);
      const response = await exportAdExcel({
        pageNumber,
        perPage,
        sort: sortFormatted,
        ...filter,
      });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "ads.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setDownloadLoading(false);
      toast.success(successDownload);
    } catch (e) {
      toast.error(unknownError);
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <PageTitle
        title="Fb Ads"
        exportLoading={downloadLoading}
        hasExport
        onExport={onExport}
      />
      <DataTable<Ads, AdsFilters>
        data={data}
        sort={sort}
        columns={columns}
        loading={loading}
        perPage={perPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortChange={handleSortChange}
        initialState={{
          columnPinning: { left: ["adId", "adSetName"] },
          density: "compact",
        }}
        columnResizeMode="onChange"
        enableColumnResizing
        filters={filter}
        onFilterChange={setFilter}
      />
      <AdForm
        onClose={handleCloseForm}
        open={isOpenFrom}
        id={selectedId}
        onSuccess={onSuccessCreateUpdate}
      />
    </>
  );
};
