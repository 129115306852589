import React from "react";
import { MarketingBudget } from "types";
import { TableCellTooltip, TableActionMenu } from "components";
import { MRT_ColumnDef } from "material-react-table";

interface Props {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export const getColumns = ({
  onEdit,
  onDelete,
}: Props): MRT_ColumnDef<MarketingBudget>[] => [
  {
    accessorKey: "id",
    header: "Id",
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    accessorKey: "price",
    header: "Price",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "title",
    header: "Title",
    enableSorting: true,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "period",
    header: "Period",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "reachedCount",
    header: "Reached Count",
    enableColumnFilter: false,
    enableSorting: true,

    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },

  {
    accessorKey: "date" as any,
    header: "",
    enableSorting: false,
    enableResizing: false,
    enableColumnFilter: false,
    size: 50,
    Cell({ row }) {
      return (
        <TableActionMenu
          id={Number(row.original.id)}
          editText="Edit"
          onEdit={onEdit}
          onDelete={onDelete}
        />
      );
    },
  },
];
