import { Button } from "@mui/material";
import { styled, css } from "@mui/material/styles";

export const ButtonStyled = styled(Button)`
  align-items: center;
  margin-left: 16px;
`;

export const FlexDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;
