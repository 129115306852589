import {
  createSlice,
  createAsyncThunk,
  AsyncThunkPayloadCreator,
} from "@reduxjs/toolkit";
import {
  getUsers,
  localStorageService,
  authService,
  LoginRequest,
} from "services";
import { User } from "types";

type AsyncThunkVoid<T> = AsyncThunkPayloadCreator<any, LoginRequest, {}>;

export const getUser = createAsyncThunk<User>(
  "auth/fetchByIdStatus",
  async (_, thunkAPI) => {
    try {
      const response = await getUsers();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(false);
    }
  }
);

const loginAsyncThunk: AsyncThunkVoid<LoginRequest> = async (
  data,
  thunkAPI
) => {
  try {
    const authData: any = await authService.login(data);
    localStorageService.setAuth(authData);
    thunkAPI.dispatch(getUser());
  } catch (error: any) {
    const errorData = error?.response?.data;
    const errorMessage =
      errorData?.Message ||
      errorData.ValidationMessages ||
      "Something went wrong";
    return thunkAPI.rejectWithValue(errorMessage);
  }
};

export const login = createAsyncThunk("auth/login", loginAsyncThunk);

const authToken = localStorageService.getToken();

interface AuthStore {
  authenticated: boolean;
  user: User | null;
  loading: boolean;
  error: null | string;
  initialLoading: boolean;
}

const initialState: AuthStore = {
  initialLoading: true,
  authenticated: false,
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthError(state) {
      state.error = "";
    },
    logOut(state) {
      state.authenticated = false;
      state.user = null;
      state.error = null;
    },
    setLoading(state, action) {
      state.initialLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.authenticated = true;
      state.error = null;
      state.initialLoading = false;
    });

    builder.addCase(getUser.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.initialLoading = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
      state.initialLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.error = action.payload as any;
    });
  },
});

export const { reducer: authReducer } = authSlice;
export const { clearAuthError, logOut, setLoading } = authSlice.actions;
