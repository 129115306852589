import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import {
  getApiConfigurationRequest,
  getSourceTypesRequest,
  GetTableResponse,
} from "services";
import {
  ApiConfigurations,
  ApiConfigurationsFilter,
  ApiSourceTypes,
  TableGetParams,
} from "types";

interface ApiConfigurationsSlices {
  data: ApiConfigurations[];
  loading: boolean;
  totalCount: number;
  sourceTypes: ApiSourceTypes[];
  sourceTypesLoading: boolean;
}

export const getApiConfigurations = createAsyncThunk<
  GetTableResponse<ApiConfigurations>,
  TableGetParams<ApiConfigurationsFilter>
>("apiConfigurations/getApiConfigurations", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getApiConfigurationRequest({ ...params, sort });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

export const getSourceTypes = createAsyncThunk(
  "apiConfigurations/getSourceTypes",
  async (_, thunkAPI) => {
    try {
      const response = await getSourceTypesRequest();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(false);
    }
  }
);

const initialState: ApiConfigurationsSlices = {
  data: [],
  loading: false,
  totalCount: 0,
  sourceTypes: [],
  sourceTypesLoading: false,
};

const apiConfigurationsSlices = createSlice({
  name: "apiConfigurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApiConfigurations.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getApiConfigurations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getApiConfigurations.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getSourceTypes.pending, (state, action) => {
      state.sourceTypesLoading = true;
    });
    builder.addCase(getSourceTypes.fulfilled, (state, action) => {
      state.sourceTypesLoading = false;
      state.sourceTypes = action.payload;
    });
    builder.addCase(getSourceTypes.rejected, (state, action) => {
      state.sourceTypesLoading = false;
    });
  },
});

export const { reducer: apiConfigurationsReducer } = apiConfigurationsSlices;
