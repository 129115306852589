import React, { FC } from "react";
import { TextField } from "@mui/material";

interface Props {
  type?: string;
  placeholder?: string;
  label?: string;
  onChange?: (e: any) => void;
  value?: string;
  error?: string;
  required?: boolean;
  name?: string;
}

export const Input: FC<Props> = ({
  required = false,
  type,
  placeholder,
  label = "",
  onChange,
  value,
  error = "",
  name,
}) => {
  return (
    <TextField
      required={required}
      placeholder={placeholder}
      helperText={error}
      onChange={onChange}
      value={value}
      label={label}
      type={type}
      error={!!error}
      fullWidth
      margin="normal"
      name={name}
      autoComplete="on"
    />
  );
};
