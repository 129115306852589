export const endpoints = {
  login: "/v1.0/User/authenticate",
  posts: "/posts",
  users: "/v1.0/User/branding",
  refreshToken: "/v1.0/User/refresh-token",
  getAudience: "/Backoffice/Audiences/fb/filter",
  audience: "/Backoffice/Audiences/fb",
  getApiConfigurations: "/Backoffice/ApiConfigurations/filter",
  apiConfigurations: "/Backoffice/ApiConfigurations",
  analytics: "/Backoffice/Analytics/lite",
  getTransaction: "/Backoffice/Payment/transaction/filter",
  getMarketingBudget: "/Backoffice/MarketingBudgets/filter",
  marketingBudget: "/Backoffice/MarketingBudgets",
  getApplicationLog: "/v1.0/ExceptionLog/filter",
  exportExcel: "/Backoffice/Payment/transaction/excel",
  refund: "/Backoffice/Payment/refund",
  adExcelExport: "/Backoffice/Ads/excel",
  getAdsConfiguration: "/Backoffice/AdConfigurations/filter",
  getAds: "/Backoffice/Ads/filter",
  putAd: "/Backoffice/Ads",
  getSourceTypes: "/Backoffice/ApiConfigurations/sourcetypes",
} as const;
