import { CreateAudienceFelids, Audience } from "types";
import { defaultPageNumber, defaultRowsPerPage, endpoints } from "constant";
import { api } from "./http";
import { GetAudienceRequestParams, GetAudienceResponse } from "./types";

export const getAudienceRequest = async ({
  perPage = defaultRowsPerPage,
  pageNumber = defaultPageNumber,
  type,
  sort,
  cluster,
}: GetAudienceRequestParams): Promise<{ data: GetAudienceResponse }> =>
  await api.post(endpoints.getAudience, {
    perPage,
    pageNumber,
    sort,
    cluster,
    type,
  });

export const deleteAudienceRequest = async (id: number): Promise<any> =>
  await api.delete(`${endpoints.audience}/${id}`, { params: { company: 1 } });

export const createAudienceRequest = async (
  values: CreateAudienceFelids
): Promise<Audience> => await api.post(endpoints.audience, values);

export const updateAudienceRequest = async (
  id: number,
  values: CreateAudienceFelids
): Promise<Audience> => await api.put(`${endpoints.audience}/${id}`, values);
