import axios from "axios";
import { BASE_URL, endpoints } from "constant";
import { localStorageService } from "services";
import { logOut } from "store/slices";
import { store } from "store/store";
const unauthorizedStatusCodes = [401, 403];

const getToken = () => {
  return localStorageService.getToken() || "";
};

const refreshAccessToken = async () => {
  const authData = localStorageService.getAuth();

  if (!authData) {
    return;
  }

  const { refreshToken, uniqueIdentifier } = authData;

  try {
    const refreshTokenResponse = await fetch(
      BASE_URL + endpoints.refreshToken,
      {
        method: "POST",
        body: JSON.stringify({ refreshToken, uniqueIdentifier }),
        headers,
      }
    );

    if (refreshTokenResponse.status === 200) {
      const responseBody = await refreshTokenResponse.json();
      localStorageService.setAuth({ ...responseBody, uniqueIdentifier });
      return responseBody.token;
    } else {
      store.dispatch(logOut());
    }
    localStorageService.setAuth("");
  } catch (error) {
    store.dispatch(logOut());
  }
};

// {
//   "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InB1cmxpbnFhdGVzdEBnbWFpbC5jb20iLCJuYW1laWQiOiIyMTc3NSIsInJvbGUiOiJbXCJBZ2VudFwiLFwiQWRtaW5pc3RyYXRvclwiLFwiTWFuYWdlclwiXSIsIlBlcm1pc3Npb25zIjoiW1wiUHVybGluXCIsXCJEb3VnbGFzRWxsaW1hblwiLFwiRGV2ZWxvcGVyXCJdIiwibmJmIjoxNjYzMjQ4MTQ2LCJleHAiOjE2NjMzMzQ1NDYsImlhdCI6MTY2MzI0ODE0Nn0.x_RUPPu94yjbyMj1-XFrloxnefmweVh33kkuUgIUnTU",
//   "refreshToken": "kCp3viQJpphUR3Qd/WbQWBw41lLXGHNQmoflxQfmo6QdSuSkdBcEssllIzhks8JVWhHdGEqyAp15xwvHSGhq8g==",
//   "uniqueIdentifier": "44c3eccb-5218-49a0-83f6-32a27c01df10"
// }
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const api = axios.create({
  baseURL: BASE_URL,
  headers,
});

api.interceptors.request.use((config) => {
  const token = getToken();

  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    return config;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // const { dispatch } = store;

    if (
      unauthorizedStatusCodes.indexOf(error.response.status) !== -1 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const token = await refreshAccessToken();

      if (token) {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export { api };
