import React, { FC } from "react";
import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
export const SmallCardSkeleton: FC = () => {
  return (
    <Grid item flex={1}>
      <Card
        sx={{
          boxShadow: "0 20px 27px 0 rgb(0 0 0 / 5%)",
          bgcolor: "white",
          borderRadius: 5,
          width: "auto",
        }}
      >
        <CardContent>
          <Skeleton variant="text" width="35%" sx={{ mb: 1.5, mt: 1 }} />
          <Skeleton variant="text" width="45%" height="55px" sx={{ mt: 1 }} />
          <Skeleton variant="text" width="35%" sx={{ mb: 1.5 }} />
          <Skeleton variant="text" width="65%" />
          <Skeleton variant="text" width="65%" />
        </CardContent>
      </Card>
    </Grid>
  );
};
