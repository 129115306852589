import React, { FC, memo, useState } from "react";
import { MenuItem, Menu, Fade, IconButton, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { noop } from "lodash";

interface Props {
  onDelete?: (id: number) => void;
  onEdit: (id: number) => void;
  isDeletable?: boolean;
  isEditable?: boolean;
  editText?: string;
  id: number;
}

const TableActionMenuComponent: FC<Props> = ({
  id,
  onDelete = noop,
  onEdit,
  isDeletable = true,
  isEditable = true,
  editText = "Edit",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(id);
    onClose();
  };
  const handleEdit = () => {
    onEdit(id);
    onClose();
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <MoreVert />
      </IconButton>
      <Menu
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleEdit} disabled={!isEditable}>
          <Typography>{editText}</Typography>
        </MenuItem>
        {isDeletable && <MenuItem onClick={handleDelete} disabled={!isDeletable}>
          <Typography color="red">Delete</Typography>
        </MenuItem>}
      </Menu>
    </>
  );
};

export const TableActionMenu = memo(TableActionMenuComponent);
