import { GoalEnum } from "./Main";

export enum ApiAgencyEnum {
  Testing = "Testing",
  Purlin = "Purlin",
  DouglasElliman = "DouglasElliman",
}

export interface MainAnalytics {
  agenciesTotalCount: number;
  adsTotalCount: number;
  agencyLiteAnalyticsData: AgencyLiteAnalyticsData[];
}

export interface AgencyLiteAnalyticsData {
  agency: ApiAgencyEnum;
  agentsCount: number;
  adsCountByGoal: AdsCountByGoal[];
}

export interface AdsCountByGoal {
  adsCount: number;
  goal: GoalEnum;
}
