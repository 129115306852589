import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { ConfirmationPopup, DataTable, PageTitle } from "components";
import { SortModel, MarketingBudget, MarketingBudgetFilter } from "types";
import { defaultPageNumber, defaultRowsPerPage, messages } from "constant";
import { getColumns } from "./columns";
import { selectMarketingBudget } from "store/select";
import { getMarketingBudgets } from "store/slices";
import { MarketingBudgetForm } from "./MarketingBudgetForm";
import { deleteMarketingBudgetsRequest } from "services";
import { toast } from "react-toastify";

const { marketingBudget, unknownError } = messages;

export const MarketingBudgets = () => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [filter, setFilter] = useState<MarketingBudgetFilter>({});
  const [sort, setSortModel] = useState<SortModel>([]);
  const [perPage, setPerPage] = useState(defaultRowsPerPage);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [selectedId, setId] = useState<number>();
  const [isOpenFrom, setOpenForm] = useState(false);
  const { data, loading, totalCount } = useSelector(selectMarketingBudget);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const onPageSizeChange = useCallback((newPerPage: number) => {
    setPerPage(newPerPage);
    setPageNumber(defaultPageNumber);
  }, []);

  const onPageChange = useCallback((newPage: number) => {
    setPageNumber(newPage);
  }, []);

  const handleSortChange = (newSort: SortModel) => {
    setSortModel(newSort);
    setPageNumber(defaultPageNumber);
  };

  useEffect(() => {
    dispatch(getMarketingBudgets({ pageNumber, perPage, sort, ...filter }));
  }, [pageNumber, perPage, sort, filter, dispatch]);

  const handleOpenForm = useCallback(() => {
    setOpenForm(true);
  }, []);

  const onEdit = useCallback((id: number) => {
    setId(id);
    handleOpenForm();
  }, []);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    if (selectedId) {
      setId(undefined);
    }
  }, [selectedId]);

  const onSuccessCreateUpdate = useCallback(() => {
    dispatch(getMarketingBudgets({ pageNumber, perPage, sort, ...filter }));
    handleCloseForm();
  }, [pageNumber, perPage, sort, filter, dispatch]);

  const onCloseDeletePopup = () => {
    setOpenConfirmation(false);
    if (selectedId) {
      setId(undefined);
    }
  };
  const onSubmitDelete = async () => {
    if (selectedId) {
      setDeleteLoading(true);
      try {
        await deleteMarketingBudgetsRequest(selectedId);
        toast.success(marketingBudget.deleted);
        dispatch(getMarketingBudgets({ pageNumber, perPage, sort, ...filter }));
        setDeleteLoading(false);
        onCloseDeletePopup();
      } catch (err) {
        setDeleteLoading(false);
        toast.error(unknownError);
      }
    }
  };

  const onDelete = useCallback((id: number) => {
    setOpenConfirmation(true);
    setId(id);
  }, []);

  const columns = useMemo(
    () => getColumns({ onEdit, onDelete }),
    [onEdit, onDelete]
  );

  return (
    <>
      <PageTitle
        title="Marketing Budget"
        isAddButton
        onClick={handleOpenForm}
      />
      <DataTable<MarketingBudget, MarketingBudgetFilter>
        data={data}
        sort={sort}
        columns={columns}
        loading={loading}
        perPage={perPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortChange={handleSortChange}
        enableColumnResizing
        columnResizeMode="onChange"
        filters={filter}
        onFilterChange={setFilter}
      />
      <MarketingBudgetForm
        onClose={handleCloseForm}
        open={isOpenFrom}
        audienceId={selectedId}
        onSuccess={onSuccessCreateUpdate}
      />
      <ConfirmationPopup
        onClose={onCloseDeletePopup}
        open={openConfirmation}
        title={marketingBudget.deleteConfirmationTitle}
        message={marketingBudget.deleteConfirmationMessage}
        onSubmit={onSubmitDelete}
        loading={deleteLoading}
      />
    </>
  );
};
