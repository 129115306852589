import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFormattedSort } from "helpers";
import { getAudienceRequest, GetTableResponse } from "services";
import { Audience, AudienceFilter, TableGetParams } from "types";

interface AudienceSlices {
  data: Audience[];
  loading: boolean;
  totalCount: number;
}

export const getAudience = createAsyncThunk<
  GetTableResponse<Audience>,
  TableGetParams<AudienceFilter>
>("audience/getAudience", async (params, thunkAPI) => {
  try {
    const sort = getFormattedSort(params.sort);
    const response = await getAudienceRequest({ ...params, sort });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(false);
  }
});

const initialState: AudienceSlices = {
  data: [],
  loading: false,
  totalCount: 0,
};

const audienceSlices = createSlice({
  name: "audience",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAudience.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAudience.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getAudience.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { reducer: audienceReducer } = audienceSlices;
