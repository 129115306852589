import { RootState } from "store/store";
import { ApplicationLog } from "types";

export const selectApplicationLog = (state: RootState) => state.applicationLog;
export const selectApplicationLogData = (state: RootState) =>
  state.applicationLog.data;
export const selectApplicationLogLoading = (state: RootState) =>
  state.applicationLog.loading;

export const selectApplicationLogById =
  (id?: number) =>
  (state: RootState): ApplicationLog | undefined =>
    id ? state.applicationLog.data.find((item) => item.id === id) : undefined;
