import { AdsConfiguration, AdsConfigurationFilters } from "types";
import { defaultPageNumber, defaultRowsPerPage, endpoints } from "constant";
import { api } from "./http";
import { GetTableResponse, TableGetRequest } from "./types";

export const getAdsConfigurationRequest = async ({
  perPage = defaultRowsPerPage,
  pageNumber = defaultPageNumber,
  sort,
  ...filter
}: TableGetRequest<AdsConfigurationFilters>): Promise<{
  data: GetTableResponse<AdsConfiguration>;
}> =>
  await api.post(endpoints.getAdsConfiguration, {
    perPage,
    pageNumber,
    sort,
    ...filter,
  });
