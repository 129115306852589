import React, { useEffect, useState } from "react";
import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_ColumnDef,
} from "material-react-table";
import { TableDefaultProps, SortModel } from "types";
import { UI } from "components";
import { defaultPageNumber, rowsPerPageOptions } from "constant";
import { isEqual } from "lodash";

type Props<T extends Record<string, any>, D> = TableDefaultProps &
  MaterialReactTableProps<T> & {
    data: T[];
    loading: boolean;
    totalCount: number;
    columns: Array<MRT_ColumnDef<T>>;
    onPageSizeChange: (newPageSize: number) => void;
    onPageChange: (newPage: number) => void;
    onSortChange: (newSort: SortModel) => void;
    sort: SortModel;
    filters: D;
    onFilterChange: (filters: D) => void;
  };

const muiLinearProgressProps = {
  sx: { display: "none" },
};
const muiTableContainerProps = {
  sx: { height: "calc(100vh - 216px)" },
};
const muiTableHeadCellProps = {
  sx: {
    // height: 57,
    background: "white",
    verticalAlign: "baseline",
    textAlign: "left",
    zIndex: 100,
    "&>div": {
      justifyContent: "left",
    },
  },
};
const muiTableBodyCellProps = {
  sx: {
    background: "white",
    // textAlign: "center",
  },
};
const muiTableProps = {
  sx: {
    tableLayout: "fixed",
    backgroundColor: "white",
  },
};

const muiTablePaginationProps = {
  rowsPerPageOptions: rowsPerPageOptions,
};

const transformFilters = (filters: Record<string, unknown>) => {
  const result = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const element = filters[key];
      result.push({
        id: key,
        value: element,
      });
    }
  }
  return result;
};

const isEqualFilters = (
  tableFilter: Record<string, unknown>,
  columnFilters: { id: string; value: unknown }[]
) => {
  if (columnFilters.length === 0) {
    if (Object.values(tableFilter).length === 0) return true;
    return false;
  }
  if (Object.values(tableFilter).length !== columnFilters.length) {
    return false;
  }

  const hasDifferent = columnFilters.some(
    (item) => tableFilter?.[item.id] !== item.value
  );
  return !hasDifferent;
};

export function DataTable<
  T extends Record<string, any>,
  D extends Record<string, unknown>
>({
  columns,
  data,
  totalCount,
  pageNumber,
  perPage,
  loading,
  onPageSizeChange,
  onPageChange,
  onSortChange,
  sort: tableSort,
  filters: tableFilter,
  onFilterChange,
  ...props
}: Props<T, D>) {
  const [sort, setSort] = useState<SortModel>(tableSort);
  const [columnFilters, setFilter] = useState<{ id: string; value: unknown }[]>(
    transformFilters(tableFilter)
  );
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: pageNumber,
    pageSize: perPage,
  });
  // need some refactor
  useEffect(() => {
    const isSameFilter = isEqualFilters(tableFilter, columnFilters);
    if (!isSameFilter) {
      if (!columnFilters[0]) {
        onFilterChange({} as any);
      } else {
        const renderFilter = (filter: { id: any; value: any }[]): D => {
          return filter.reduce((prev, item) => {
            prev[item.id] = item.value;
            return prev;
          }, {} as any);
        };
        onFilterChange(renderFilter(columnFilters));
      }
      if (pagination.pageSize !== defaultPageNumber) {
        setPagination({
          pageIndex: 0,
          pageSize: pagination.pageSize,
        });
      }
    }
  }, [columnFilters, onFilterChange, tableFilter, pagination]);

  useEffect(() => {
    const isSameSort = isEqual(tableSort, sort);
    if (!isSameSort) {
      if (!sort[0]) {
        onSortChange([]);
      } else {
        onSortChange(sort);
      }
      if (pagination.pageSize !== defaultPageNumber) {
        setPagination({
          pageIndex: 0,
          pageSize: pagination.pageSize,
        });
      }
    }
  }, [sort, onSortChange, tableSort, pagination]);

  useEffect(() => {
    const { pageIndex, pageSize } = pagination;
    if (pageIndex !== pageNumber) {
      onPageChange(pageIndex);
    }
    if (pageSize !== perPage) {
      onPageSizeChange(pageSize);
    }
  }, [pagination, onPageSizeChange, onPageChange, pageNumber, perPage]);

  return (
    <UI.TableContainer>
      <MaterialReactTable<T>
        data={data}
        columns={columns}
        muiLinearProgressProps={muiLinearProgressProps}
        muiTableContainerProps={muiTableContainerProps}
        muiTableHeadCellProps={muiTableHeadCellProps}
        muiTableBodyCellProps={muiTableBodyCellProps}
        muiTableProps={muiTableProps}
        muiTablePaginationProps={muiTablePaginationProps}
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enablePagination={true}
        getRowId={(row, index) => {
          if (!row.id) return `${index}`;
          return `${row.id}`;
        }}
        sortDescFirst={false}
        manualSorting
        manualPagination
        manualFiltering
        paginateExpandedRows
        rowCount={totalCount}
        enableSorting={true}
        enableTopToolbar
        enableDensityToggle={false}
        muiTableBodyRowProps={{ hover: false }}
        initialState={{
          density: "compact",
        }}
        state={{
          pagination: {
            pageIndex: pageNumber,
            pageSize: perPage,
          },
          isLoading: loading,
          sorting: sort,
          showSkeletons: loading,
          columnFilters: columnFilters,
        }}
        onSortingChange={setSort}
        onColumnFiltersChange={setFilter}
        onPaginationChange={setPagination}
        {...props}
      />
    </UI.TableContainer>
  );
}
