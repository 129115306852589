export enum ApiSourceType {
  LoginDESystem = "LoginDESystem",
  DEGetAllAgents = "DEGetAllAgents",
  DEGetAMPBalanceByAgentId = "DEGetAMPBalanceByAgentId",
  DEGetListingById = "DEGetListingById",
  DEGetAllNYCListings = "DEGetAllNYCListings",
  DEGetAgentListingsByIdInNYCLite = "DEGetAgentListingsByIdInNYCLite",
  DataEnhancement = "DataEnhancement",
  AdCampaignCreateAPI = "AdCampaignCreateAPI",
  AdCampaignDeleteAPI = "AdCampaignDeleteAPI",
  AdSetCreateAPI = "AdSetCreateAPI",
  AdSetReadAPI = "AdSetReadAPI",
  MultipleAdSetsReadAPI = "MultipleAdSetsReadAPI",
  FBTargetingSearch = "FBTargetingSearch",
  AdCreativeCreateAPI = "AdCreativeCreateAPI",
  AdCreateAPI = "AdCreateAPI",
  VectorizeHouse = "VectorizeHouse",
  LeadFormCreateAPI = "LeadFormCreateAPI",
  GetImageHashFromFB = "GetImageHashFromFB",
  EnhancementAndSimilarity = "EnhancementAndSimilarity",
  DeleteHomeVector = "DeleteHomeVector",
  GetSimilarHomes = "GetSimilarHomes",
  AdLabelCreateAPI = "AdLabelCreateAPI",
  ConversionsApi = "ConversionsApi",
  CustomAudienceAPI = "CustomAudienceAPI",
  EnhanceAndGetSimilarsDeProperty = "EnhanceAndGetSimilarsDeProperty",
  BuildPixelDataById = "BuildPixelDataById",
  ShareAudienceAPI = "ShareAudienceAPI",
  SharedAudienceInfoAPI = "SharedAudienceInfoAPI",
  AllAudiencesAPI = "AllAudiencesAPI",
  AudienceInfoAPI = "AudienceInfoAPI",
  DEGetAllTeams = "DEGetAllTeams",
  ExchangeUserAccessTokenAPI = "ExchangeUserAccessTokenAPI",
  GetFbPageAccessTokenAPI = "GetFbPageAccessTokenAPI",
  InsightsApi = "InsightsApi",
  GoogleAnalyticsApi = "GoogleAnalyticsApi",
  AdUpdateApi = "AdUpdateApi",
  DEWebAnalytics = "DEWebAnalytics",
  FBLeadRetrievalApi = "FBLeadRetrievalApi",
  GoogleAnalyticsAdsApi = "GoogleAnalyticsAdsApi",
  LoginPurlinSystem = "LoginPurlinSystem",
  GetAllPurlinAgents = "GetAllPurlinAgents",
  BuildCFPropertyFromPurlinModel = "BuildCFPropertyFromPurlinModel",
  PurlinGetListingById = "PurlinGetListingById",
  PurlinGetListingByIdLite = "PurlinGetListingByIdLite",
  EmailSenderAddresses = "EmailSenderAddresses",
  FbLeadsDataRetrievalAPI = "FbLeadsDataRetrievalAPI",
  PurlinGetListingURLById = "PurlinGetListingURLById",
  FbEffectiveStatusUrl = "FbEffectiveStatusUrl",
  FbPageRunningOrInReviewAdsCountAPI = "FbPageRunningOrInReviewAdsCountAPI",
  FairHousingAPI = "FairHousingAPI",
}

export interface ApiConfigurations {
  id: number;
  sourceType: ApiSourceType;
  value: "string";
}

export type ApiConfigurationsFilterChange = (
  filter: ApiConfigurationsFilter
) => void;

export interface ApiConfigurationsFilter extends Record<string, unknown> {
  sourceType?: ApiSourceType;
}

export interface ApiConfigurationsCreateFields {
  sourceType: ApiSourceType | number;
  value?: string;
}

export interface ApiSourceTypes {
  type: number;
  name: ApiSourceType;
}
