import React, { FC, useMemo, useState, useEffect } from "react";
import { FormDrawer } from "components";
import { messages } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createApiConfigurationRequest,
  updateApiConfigurationRequest,
} from "services";
import { ApiConfigurationsCreateFields, ApiSourceTypes } from "types";
import { Form } from "./Form";
import {
  selectSelectedApiConfigurationsById,
  selectApiSourceTypesData,
} from "store/select";
import { getSourceTypes } from "store/slices";
import { AppDispatch } from "store/store";

const { apiConfiguration, unknownError } = messages;

const defaultInitialValues = {
  sourceType: undefined,
  value: "",
} as const;

const getFormattedSourceTypes = (sourceTypes: ApiSourceTypes[]) =>
  sourceTypes.map((item) => ({
    label: item.name,
    value: item.type,
  }));

interface Props {
  onClose: () => void;
  open: boolean;
  apiConfigurationId?: number;
  onSuccess: () => void;
}

const handleCreateUpdateApiConfigurations = (
  values: ApiConfigurationsCreateFields,
  apiConfigurationId?: number
) =>
  apiConfigurationId
    ? updateApiConfigurationRequest(apiConfigurationId, values)
    : createApiConfigurationRequest(values);

export const ApiConfigurationForm: FC<Props> = ({
  onClose,
  open,
  onSuccess,
  apiConfigurationId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const sourceTypes = useSelector(selectApiSourceTypesData);
  const selectedApiConfiguration = useSelector(
    selectSelectedApiConfigurationsById(apiConfigurationId)
  );

  const onSubmit = async (values: ApiConfigurationsCreateFields) => {
    setLoading(true);
    try {
      await handleCreateUpdateApiConfigurations(values, apiConfigurationId);
      const successMessage = apiConfigurationId
        ? apiConfiguration.updated
        : apiConfiguration.created;

      toast.success(successMessage);
      setLoading(false);
      onSuccess();
    } catch (err) {
      setLoading(false);
      toast.error(unknownError);
    }
  };

  useEffect(() => {
    dispatch(getSourceTypes());
  }, []);

  const formattedSourceTypes = useMemo(() => {
    return getFormattedSourceTypes(sourceTypes);
  }, [sourceTypes]);

  const initialValues = useMemo(() => {
    if (!selectedApiConfiguration) return defaultInitialValues;
    return selectedApiConfiguration;
  }, [selectedApiConfiguration]);

  const title = apiConfigurationId
    ? apiConfiguration.edit
    : apiConfiguration.create;
  return (
    <FormDrawer open={open} title={title} onClose={onClose}>
      <Form
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        initialValues={initialValues}
        sourceTypes={formattedSourceTypes as any}
      />
    </FormDrawer>
  );
};
