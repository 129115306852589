import React from "react";
import { ApiConfigurations, ApiSourceType } from "types";
import { MRT_ColumnDef } from "material-react-table";
import { TableActionMenu, TableCellTooltip } from "components";

export const apiSourceTypes = Object.values(ApiSourceType).map((item) => ({
  text: item,
  value: item,
}));

interface Props {
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

export const getColumns = ({
  onDelete,
  onEdit,
}: Props): MRT_ColumnDef<ApiConfigurations>[] => [
  {
    accessorKey: "id",
    header: "Id",
    enableSorting: true,
    enableColumnFilter: false,
    minSize: 100,
    maxSize: 100,
    size: 100,
    enableResizing: false,

  },
  {
    accessorKey: "sourceType",
    header: "Source Type",
    enableSorting: true,
    minSize: 100,
    enableResizing: false,
    size: 100,
    maxSize: 400,
    filterVariant: "select",
    filterSelectOptions: apiSourceTypes,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "value",
    header: "Value",
    enableSorting: true,
    enableColumnFilter: false,
    enableResizing: false,
    minSize: 300,
    Cell: ({ cell }) => <TableCellTooltip value={cell.getValue<string>()} />,
  },
  {
    accessorKey: "action" as any,
    header: "",
    enableSorting: false,
    enableResizing: false,
    enableColumnFilter: false,
    size: 40,
    maxSize: 40,
    minSize: 40,
    Cell: ({ row }) => {
      return (
        <TableActionMenu
          id={Number(row.original.id)}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      );
    },
  },
];
