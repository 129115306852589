import moment from "moment";
import { ResponseSortModel } from "services";
import { SortModel } from "types";

export function dateFormatter(date?: string) {
  if (!date) return "";
  try {
    const dateFormat = new Date(date);
    const result = moment(dateFormat).format("DD-MM-YYYY");
    return result;
  } catch (err) {
    return "";
  }
}

export function getFirstCharacter(str = "", defaultValue = "") {
  if (!str) return defaultValue;
  const firstCharacter = str.charAt(0);
  if (firstCharacter) return firstCharacter;
  return defaultValue;
}

export function currencyFormatter(numberVal?: number | string) {
  if (numberVal === 0) {
    return "-";
  } else {
    return `$ ${numberVal}`;
  }
}

export function percentageFormatter(numberVal?: number | string) {
  if (numberVal === 0) {
    return "-";
  } else {
    return `${numberVal}%`;
  }
}

export function firstLetterFormatter(stringVal?: string) {
  return stringVal?.charAt(0).toUpperCase();
}

export const getFormattedSort = (
  sort: SortModel
): ResponseSortModel | undefined => {
  if (!sort.length) return undefined;
  const sortSingle = sort[0];

  if (!sortSingle.id) return undefined;

  return {
    fieldName: sortSingle.id,
    logic: sortSingle.desc ? "desc" : "asc",
  };
};

export function getZeroAsTruthyValue(value?: string) {
  return Number(value) || Number(value) === 0 ? Number(value) : undefined;
}

export function dateFormatterNew(date?: string) {
  if (!date) return "";
  try {
    const dateFormat = new Date(date);
    const result = moment(dateFormat).format("YYYY-DD-MM  h:mm:ss.SSS ");
    return result;
  } catch (err) {
    return "";
  }
}

export function twoSymbolsAfterDot(value: string) {
  if (!value) return "";
  try {
    return Math.trunc(Number(value) * Math.pow(10, 2)) / Math.pow(10, 2);
  } catch (err) {
    return "";
  }
}
