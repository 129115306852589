import { styled } from "@mui/material/styles";

export const SignInDiv = styled("form")`
    padding: 0;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
    overflow: hidden;
    min-height: 400px;
  `

export const LeftContainer = styled("div")`
width: 50%;
`
export const Card = styled("div")`
      max-width: 350px;
      width: 100%;
      margin: auto;     
`

export const CardHeader = styled("div")`
max-width: 350px;
width: 100%;
margin: auto;     
`

export const RightContainer = styled("div")`
      min-height: 400px;
      width: 50%;
      height: calc(100vh - 2rem);
      background-image: linear-gradient(310deg, #7928ca, #ff0080);
      border-radius: 0.75rem;
      margin: 1rem;
      position: relative;  
      display: flex;
      flex-direction: column;
      text-align: center;
`

export const Lines = styled("div") `
    width: 100%;
    height: 100%;
    min-height: 400px;
   
`
export const RocketImgDiv = styled("div") `
      position: absolute;
      top:20vh;
      left:0;
      right: 0;
      bottom: 0;
      margin: auto;
      padding-left: 6rem;
      padding-right: 6rem;
      
`

export const RocketImg = styled("img") `
   max-width: 600px;
   height: 45vh;
   min-height: 200px;
`

export const LinesImg = styled("img")`
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;


`