import React, { FC, ReactElement, useEffect } from "react";
import { PageTitle, StatCard } from "components";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectMainAnaltytics } from "store/select";
import { getMainAnalytics } from "store/slices/MainAnalyticsSlices";
import { AppDispatch } from "store/store";
import { SmallStatCard } from "components/SmallStatcard";
import { SmallCardSkeleton } from "components/Skeletons/SmallCardsSkeleton";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import InsightsIcon from "@mui/icons-material/Insights";

interface Props {
  loading: boolean;
  children: ReactElement;
}

const arr = [1, 2, 3, 4, 5];

export const DashboardCard: FC<Props> = ({ loading, children }) => {
  if (loading) return <SmallCardSkeleton />;
  return children;
};

export const Dashboard: FC = () => {
  const { data, loading } = useSelector(selectMainAnaltytics);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getMainAnalytics());
  }, []);

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={2}>
        <StatCard
          loading={loading}
          title="All agencies"
          value={data?.agenciesTotalCount}
          icon={<WorkOutlineIcon fontSize="medium" color="secondary" />}
        />
        <StatCard
          loading={loading}
          title="All ads"
          value={data?.adsTotalCount}
          icon={<InsightsIcon fontSize="medium" color="secondary" />}
        />
      </Grid>
      <Grid container spacing={2} marginBottom={4} marginTop={2}>
        {loading
          ? arr.map(function (item, index) {
              return <SmallCardSkeleton key={index} />;
            })
          : null}
        {loading
          ? null
          : data?.agencyLiteAnalyticsData.map((item, index) => {
              return (
                <SmallStatCard
                  key={index}
                  agency={item.agency}
                  agentsCount={item.agentsCount}
                  adsCountByGoal={item.adsCountByGoal}
                />
              );
            })}
      </Grid>
    </>
  );
};
