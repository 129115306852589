import React, { FC, useId } from "react";
import { MarketingBudget as MarketingBudgets } from "types";
import { IconButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";


interface Props {
  marketingBudget: MarketingBudgets[];
}

export const MarketingBudget: FC<Props> = ({ marketingBudget }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = useId();
  return (
    <>
      <Typography
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          textAlign: "left",
        }}
        onClick={handleClick}
      >
        Details
      </Typography>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id={id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
        <Box sx={{ width: 200, pl: 2, pt: 2 }}>
          {marketingBudget.map((details, index) => {
            return (
              <div key={index}>
                <Typography variant="body1" color="#cb0c9f" fontWeight={600}>
                  {details.title}
                </Typography>
                <Typography variant="body1" color="#3a416f">
                  {`ID: ${details.id}`}
                </Typography>
                <Typography variant="body1" color="#3a416f">
                  {`Period: ${details.period}`}
                </Typography>
                <Typography variant="body1" color="#3a416f">
                  {`Price: ${details.price}`}
                </Typography>
                <Typography sx={{ pb: 3 }} variant="body1" color="#3a416f">
                  {`Reached: ${details.reachedCount}`}
                </Typography>
              </div>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};
