import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RightContainerNew = styled("div")`
  width: 70%;
  height: calc(100vh - 2rem);
  margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const TypographyPurple = styled(Typography)`
  background: -webkit-linear-gradient(310deg, #7928ca, #ff0080);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 80px;
  padding-bottom: 12px;
`;

export const MainContainer = styled("div")`
  padding: 0;
  display: flex;
  align-items: start;
  background-size: cover;
  background-position: 50%;
`;

export const ImgDiv = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-left: 6rem;
  padding-right: 6rem;
`;
