export enum AudienceType {
  Default = "Default",
  Standard = "Standard",
}

export interface Audience {
  estimatedSize: number;
  fbId: string;
  id: number;
  name: string;
  sizeSentToPixel: number;
  specialAdAudienceFbId: string;
  specialAdAudienceName: string;
  type: AudienceType;
  clusterId?: string;
}

export interface CreateAudienceFelids {
  fbId: string;
  name: string;
  specialAdAudienceFbId: string;
  clusterId: string;
  specialAdAudienceName: string;
}

export interface AudienceFilter {
  cluster?: string;
  type?: AudienceType;
}

export type TypeFilterParams = {
  field: "type";
  value?: AudienceType;
};

export type ClusterFilterParams = {
  field: "cluster";
  value?: string;
};
