import { ApplicationLog, ApplicationLogFilters} from "types";
import { endpoints } from "constant";
import { api } from "./http";
import { GetTableResponse, TableGetRequest } from "./types";

export const getApplicationLogRequest = async (
  params: TableGetRequest<ApplicationLogFilters>
): Promise<{
  data: GetTableResponse<ApplicationLog>;
}> => await api.post(endpoints.getApplicationLog, params);
