import React, { FC } from "react";
import { useFormik } from "formik";
import { InputAdornment, TextField } from "@mui/material";
import { StyledForm } from "./styles";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { validationSchema } from "./validationSchema";

interface Props {
  onSubmit: (values: { amount: number }) => void;
  maxValue: number;
  initialValues: {
    amount: number;
  };
  loading: boolean;
}

export const Form: FC<Props> = ({
  onSubmit,
  initialValues,
  loading,
  maxValue,
}) => {
  const formik = useFormik<{ amount: number }>({
    validationSchema: validationSchema(maxValue),
    initialValues,
    onSubmit,
  });
  return (
    <StyledForm onSubmit={formik.handleSubmit} noValidate>
      <TextField
        margin="dense"
        required
        fullWidth
        type="number"
        name="amount"
        label="Amount"
        value={formik.values.amount}
        onChange={formik.handleChange}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        autoComplete="off"
        inputProps={{ pattern: "[0-9]*" }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />

      <Box mt="auto" />
      <LoadingButton
        loading={loading}
        color="primary"
        variant="gradient"
        fullWidth
        type="submit"
      >
        Submit
      </LoadingButton>
    </StyledForm>
  );
};
