import React, { FC } from "react";
import { PageTitle } from "components";

export const Analytics: FC = () => {
  return (
    <>
      <PageTitle title="Analytics" />
    </>
  );
};
