import React, { FC, useEffect } from "react";
import type { AppDispatch } from "store/store";
import { useSelector, useDispatch } from "react-redux";
import { AuthRouter } from "routes";
import { selectAuth } from "store/select";
import { getUser, setLoading } from "./../store/slices/authSlices";
import { InitialLoading } from "components";
import { localStorageService } from "services";

interface Props {
  children: React.ReactElement;
}

export const Authentication: FC<Props> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialLoading, authenticated } = useSelector(selectAuth);
  useEffect(() => {
    const authToken = localStorageService.getToken();
    if (authToken) {
      dispatch(getUser());
    } else {
      dispatch(setLoading(false));
    }
  }, []);

  if (initialLoading) return <InitialLoading />;

  if (!authenticated) return <AuthRouter />;

  return children;
};
