import { FC } from "react";
import { CardContent, Grid, Typography, Card } from "@mui/material";
import {
  AgentsTitle,
  CardStyled,
  GoalTitle,
  TypographyPurple2,
} from "./styles";
import { AdsCountByGoal } from "types";
import { goalNames } from "constant";

interface Props {
  agency: string;
  agentsCount?: number | string;
  adsCountByGoal?: AdsCountByGoal[];
}

export const SmallStatCard: FC<Props> = ({
  agency,
  agentsCount,
  adsCountByGoal,
}) => {
  return (
    <Grid item flex={1}>
      <CardStyled>
        <CardContent>
          <TypographyPurple2 variant="h6">{agency}</TypographyPurple2>
          <TypographyPurple2 variant="h4" fontWeight="400">
            {agentsCount}
          </TypographyPurple2>
          <AgentsTitle color="text.secondary">{" Agents"}</AgentsTitle>
          {adsCountByGoal?.map(function (item, index) {
            return (
              <div key={index}>
                <GoalTitle variant="body2">
                  {`${goalNames[item.goal]} :`}
                </GoalTitle>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  display="inline"
                >
                  {` ${item.adsCount} `}
                </Typography>
              </div>
            );
          })}
        </CardContent>
      </CardStyled>
    </Grid>
  );
};
