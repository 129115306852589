import { ReactElement } from "react";

export type Nullable<T> = T | null;

export interface SubMenuType {
  name: string;
  path: string;
}

export interface MenuItemsType {
  name: string;
  icon: ReactElement;
  subMenu?: SubMenuType[];
  path: string;
  hidden?: boolean;
}

export enum GoalEnum {
  LEAD_GENERATION = "LEAD_GENERATION",
  LINK_CLICKS = "LINK_CLICKS",
}
