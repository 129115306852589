import {
  CreateMarketingBudgetFelids,
  MarketingBudget,
  MarketingBudgetFilter,
} from "types";
import { endpoints } from "constant";
import { api } from "./http";
import { GetTableResponse, TableGetRequest } from "./types";

export const getMarketingBudgetsRequest = async (
  params: TableGetRequest<MarketingBudgetFilter>
): Promise<{
  data: GetTableResponse<MarketingBudget>;
}> => await api.post(endpoints.getMarketingBudget, params);

export const deleteMarketingBudgetsRequest = async (id: number): Promise<any> =>
  await api.delete(`${endpoints.marketingBudget}/${id}`, {
    params: { company: 1 },
  });

export const createMarketingBudgetsRequest = async (
  values: CreateMarketingBudgetFelids
): Promise<MarketingBudget> =>
  await api.post(endpoints.marketingBudget, values);

export const updateMarketingBudgetsRequest = async (
  id: number,
  values: CreateMarketingBudgetFelids
): Promise<MarketingBudget> =>
  await api.put(`${endpoints.marketingBudget}/${id}`, values);
