import React, { FC, memo } from "react";
import { Tooltip } from "@mui/material";

interface Props {
  value?: number | string;
  title?: number | string;
}

const TableCellTooltipComponent: FC<Props> = ({ value = "" }) => {
  return (
    <Tooltip title={value || ""} placement="bottom-start" >
      <span className="MuiDataGrid-cellContent  ellipsis">{value}</span>
    </Tooltip>
  );
};

export const TableCellTooltip = memo(TableCellTooltipComponent);
