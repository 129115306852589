import { ListItemText } from "@mui/material";
import { FC, memo } from "react";
import {
  ListItemButtonSubmenu,
  ListItemStyled,
  StyledLink,
  TypographyStyled,
} from "./styles";
import { SubMenuType } from "types";
import { firstLetterFormatter } from "helpers";

interface SubMenuItemsProps extends SubMenuType {
  isActive: boolean;
  isSmall: boolean;
}

const SubMenuItemsComponent: FC<SubMenuItemsProps> = ({
  name,
  path,
  isActive,
  isSmall,
}) => {
  const subMenuName = isSmall ? firstLetterFormatter(name) : name;

  return (
    <ListItemStyled disablePadding isActive={isActive}>
      <StyledLink to={path}>
        <ListItemButtonSubmenu sx={{ pt: 0, pb: 0 }}>
          <ListItemText
            primary={
              <TypographyStyled isActive={isActive} isSmall={isSmall}>
                {subMenuName}
              </TypographyStyled>
            }
          />
        </ListItemButtonSubmenu>
      </StyledLink>
    </ListItemStyled>
  );
};

export const SubMenuItems = memo(
  SubMenuItemsComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.isActive === nextProps.isActive &&
      prevProps.isSmall === nextProps.isSmall
    );
  }
);
