import React, { memo, useMemo, useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { SortModel, TableDefaultProps } from "types";
import { UI, TableSkeleton } from "components";
import { rowsPerPageOptions } from "constant";
import { noop } from "lodash";

type DefaultData<T> = T & {
  id: number;
};

type Props<T extends GridValidRowModel> = TableDefaultProps & {
  data: T[];
  loading: boolean;
  totalCount: number;
  columns: GridColDef<T, any, any>[];
  onPageSizeChange?: (newPageSize: number) => void;
  onPageChange?: (newPage: number) => void;
  onSortChange?: (newSort: SortModel) => void;
};

function TableComponent<T, F>({
  data,
  loading,
  perPage,
  pageNumber,
  onPageSizeChange,
  onPageChange,
  totalCount,
  sort = [],
  onSortChange = noop,
  columns,
}: Props<DefaultData<T>>) {
  // TO Do
  // intermediate changes for add advances table

  const onSortModelChange = useCallback(
    (model: GridSortModel) =>
      onSortChange(
        model.map((item) => ({
          desc: item.sort === "desc",
          id: item.field,
        }))
      ),
    [onSortChange]
  );

  const formattedSort: GridSortModel = useMemo(
    () =>
      sort.map((item) => ({
        field: item.id,
        sort: item.desc ? "desc" : "asc",
      })),
    [sort]
  );

  return (
    <UI.TableContainer>
      <DataGrid
        components={{
          LoadingOverlay: TableSkeleton,
        }}
        rowCount={totalCount}
        columns={columns}
        rows={loading ? [] : data}
        page={pageNumber}
        pageSize={perPage}
        paginationMode="server"
        pagination
        rowsPerPageOptions={rowsPerPageOptions}
        loading={loading}
        getRowId={(row) => `${row.id}`}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        disableColumnSelector
        disableColumnMenu
        filterMode="server"
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        sortModel={formattedSort}
        disableExtendRowFullWidth
        disableVirtualization
      />
    </UI.TableContainer>
  );
}

export const Table = memo(TableComponent) as typeof TableComponent;
