import { RootState } from "store/store";
import { Ads } from "types";

export const selectAds = (state: RootState) => state.ads;
export const selectAdsData = (state: RootState) => state.ads.data;
export const selectAdsLoading = (state: RootState) =>
  state.ads.loading;

export const selectAdsById =
  (id?: number) =>
  (state: RootState): Ads | undefined =>
    id ? state.ads.data.find((item) => item.id === id) : undefined;
