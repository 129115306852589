import { PatchRefundParams, Transaction, TransactionFilters } from "types";
import { defaultPageNumber, defaultRowsPerPage, endpoints } from "constant";
import { api } from "./http";
import { GetTableResponse, TableGetRequest } from "./types";

export const getTransactionsRequest = async ({
  perPage = defaultRowsPerPage,
  pageNumber = defaultPageNumber,
  sort,
  ...filter
}: TableGetRequest<TransactionFilters>): Promise<{
  data: GetTableResponse<Transaction>;
}> =>
  await api.post(endpoints.getTransaction, {
    perPage,
    pageNumber,
    sort,
    ...filter,
  });

export const exportExcel = async ({
  perPage = defaultRowsPerPage,
  pageNumber = defaultPageNumber,
  sort,
  ...filter
}: TableGetRequest<TransactionFilters>): Promise<any> =>
  await api.post(
    endpoints.exportExcel,
    {
      perPage,
      pageNumber,
      sort,
      ...filter,
    },
    {
      responseType: "blob",
    }
  );

export const onRefundRequest = async (
  params: PatchRefundParams
): Promise<any> => await api.patch(endpoints.refund, params);
